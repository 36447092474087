/* eslint-disable no-unused-vars */
import "./style.css";
import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import Axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import List from "../list";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import logo from "../../images/logo1.png";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { Button, ThemeProvider, createTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from "@mui/icons-material/Facebook";
import home from "../../images/icon/home.png";
import who from "../../images/icon/who.png";
import ReactGA from "react-ga";
import { warning } from "framer-motion/dom";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1080,
      lg: 1280,
    },
  },
});

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const track_id = "G-T6622GHTPT";
    ReactGA.initialize(track_id);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const cartItems = JSON.parse(localStorage.getItem("cart"));

  const cartContain = cartItems ? cartItems.length : 0;

  const [inputText, setInputText] = useState("");
  const [showList, setShowList] = useState(false);
  const textFieldRef = useRef(null);

  const inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    setShowList(true);
  };

  const handleSearchClick = () => {
    performSearch();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      performSearch();
    }
  };

  const performSearch = () => {
    if (inputText.trim().length > 0) {
      setShowList(false);
      navigate(`/search?query=${encodeURIComponent(inputText.trim())}`);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target)
      ) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const location = useLocation();

  useEffect(() => {
    const pageTitle = location.pathname.split("/").pop();
    if (pageTitle === "") {
      // eslint-disable-next-line no-useless-concat
      document.title = "Ebuy";
    } else {
      document.title = `${decodeURIComponent(pageTitle).replace(/%20/g, " ")}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="header" id="header">
        <div className="navbarTop">
        <p class="wave-text">
  <span>Bienvenue </span>
  <span>sur </span>
  <span>eBuy :</span>
  <span> Votre </span>
  <span>solution </span>
  <span>de </span>
  <span>shopping </span>
  <span>en </span>
  <span>un </span>
  <span>clic </span>
</p>

        </div>

        <div className="navbar">
          <div className="logoDiv">
            <Link to="/">
              <img src={logo} alt="logo" className="logo" />
            </Link>

            <IconButton
              sx={{
                display: {
                  lg: "none",
                  md: "none",
                  sm: "none",
                  xs: "inline-block",
                  zIndex: 990999999999999,
                },
              }}
              onClick={toggleMenu}
            >
              {!isOpen ? (
                <MenuIcon
                  id="openIcon"
                  fontSize="small"
                  color="warning"
                  sx={{
                    top: "0px",
                    right: "0px",
                  }}
                />
              ) : (
                <CloseIcon
                  id="closeIcon"
                  color="warning"
                  fontSize="medium"
                  sx={{
                    top: "25px",
                    right: "10px",

                    position: "fixed",
                  }}
                />
              )}
            </IconButton>
            <div id="navMenu" className={isOpen ? "linkMenu show" : "linkMenu"}>
              <ol>
                <div className="socialMediaIcon">
                  <h3>Suivez-nous :</h3>
                  <a
                    href="https://www.instagram.com/ebuy.tunisie"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon sx={{ color: "#283943" }} fontSize="small" />
                  </a>
                  <a
                    href="https://www.youtube.com/@EbuyTunisia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YouTubeIcon sx={{ color: "#283943" }} fontSize="small" />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61556332296913"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon sx={{ color: "#283943" }} fontSize="small" />
                  </a>
                </div>
                <li>
                  <div className="iconContainer">
                    <a onClick={toggleMenu} className={`linkItems `} href="/">
                      <img src={home} alt="home" className="iconImg home" />
                      <span style={{ textAlign: "center" }}> Acceuil </span>
                    </a>
                  </div>
                </li>

                <li>
                  {icons.map((icon, index) => (
                    <div key={index} className="iconContainer">
                      <a
                        href={icon.lien}
                        target="_blank"
                        rel="noreferrer"
                        className="linkItems"
                      >
                        <img
                          src={require(`../../images/icon/${icon.name}.png`)}
                          alt={icon.name}
                          className="iconImg"
                        />

                        <span>{icon.name}</span>
                      </a>
                    </div>
                  ))}
                </li>
                <li>
                  <div className="iconContainer">
                    <a className={`linkItems `} href="/qui-sommes-nous">
                      <img src={who} alt="who" className="iconImg home" />
                      <span> Qui sommes-nous ? </span>
                    </a>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div className="userDiv">
            <div className="searchListDiv" ref={textFieldRef}>
              <TextField
                type="search"
                variant="outlined"
                onChange={inputHandler}
                onKeyPress={handleKeyPress}
                label="Rechercher ..."
                size="small"
                sx={{ width: "100%" }}
                color="warning"
                InputProps={{
                  endAdornment: (
                    <SearchIcon
                      color="action"
                      onClick={handleSearchClick}
                      sx={{ cursor: "pointer" }}
                    />
                  ),
                }}
              />
              <div className="searchList">
                {showList && <List input={inputText} />}
              </div>
            </div>

            <Link to="/panier">
              <IconButton aria-label="cart" color="warning" title="Panier">
                <StyledBadge badgeContent={cartContain} color="warning">
                  <ShoppingCartIcon />
                </StyledBadge>
              </IconButton>
            </Link>
          </div>
        </div>
      </div>
      <div className="navbarBottom">
        <div className="navbarIcon">
          {icons.map((icon, index) => (
            <div key={index} className="iconContainer">
              <a href={icon.lien} target="_blank" rel="noreferrer">
                <img
                  src={require(`../../images/icon/${icon.name}.png`)}
                  alt={icon.name}
                  className="iconImg"
                />

                <span>{icon.name}</span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Header;
const icons = [
  { name: "cuisine", lien: "/collections/electromenager" },
  {
    name: "maison",
    lien: "/collections/electromenager/hygiene et article maison",
  },
  { name: "beaute & sante", lien: "/collections/beaute & sante" },
  { name: "accessoires", lien: "/collections/mode & accessoires" },
  { name: "meubles", lien: "/collections/mobilier" },
  { name: "luminaires", lien: "/collections/luminaire" },
  { name: "nos marques", lien: "/nos-marques" },
  { name: "bons plans", lien: "/bons-plans" },
];
