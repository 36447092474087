/* eslint-disable no-unused-vars */
import Axios from "axios";
import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import PaymentsIcon from '@mui/icons-material/Payments';
import "./style.css";
import { useState, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button } from "@mui/material";
import UseFetch from "../../hook/useFetch";
import deliveryLogo from '../../images/best delivry .png'
export default function Panier() {
  const [isValidCode, setIsValidCode] = useState(false);
  function isExpired(timestamp) {
    const expirationTime = 5 * 24 * 60 * 60 * 1000; 
    return Date.now() - timestamp > expirationTime;
  }


  const infoString = localStorage.getItem("info");
  let infoArray = [];

  if (infoString) {
    try {
      const infoObject = JSON.parse(infoString);

      // Vérifier si l'objet est un tableau
      if (Array.isArray(infoObject)) {
        // Si c'est le cas, assignez-le à infoArray
        infoArray = infoObject;
      } else {
        // Sinon, créez un tableau contenant uniquement cet objet
        infoArray.push(infoObject);
      }
    } catch (error) {
      console.error("Erreur lors de l'analyse des données JSON:", error);
    }
  }

  if (infoString) {
    const infoObject = JSON.parse(infoString);
    if (!isExpired(infoObject.timestamp)) {
      const name = infoObject.name;
      const totalTTC = infoObject.totalTTC;
      const date1 = infoObject.date1;
      const date2 = infoObject.date2;
    } else {
      localStorage.removeItem("info");
    }
  }

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const futureDate1 = new Date(currentDate);
  futureDate1.setDate(futureDate1.getDate() + 2);
  const futureDate2 = new Date(currentDate);
  futureDate2.setDate(futureDate2.getDate() + 4);
  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("fr-FR", options);
  }
  const [code, setCode] = useState("sans code");

  const [nameValue, setNameValue] = React.useState("");
  const [adressValue, setAdressValue] = React.useState("");
  const [NumberValue, setNumberValue] = React.useState("");
 
  
  const [nameError, setNameError] = useState("");

  const handleNameChange = (event) => {
    const value = event.target.value;
    if (value.trim() === "") {
      // Définir un message d'erreur
      setNameError("Ce champ ne peut pas être vide.");
    } else {
      // Réinitialiser le message d'erreur et mettre à jour la valeur
      setNameError("");
      setNameValue(value);
    }
  };
  
  const handleAdressChange = (event) => {
    const value = event.target.value;
    if (value.trim() === "") {
      // Définir un message d'erreur
      setNameError("Ce champ ne peut pas être vide.");
    } else {
      // Réinitialiser le message d'erreur et mettre à jour la valeur
      setNameError("");
      setAdressValue(value);
    }
  }
  const [numberError, setNumberError] = useState("");
  const handleNumberChange = (event) => {
    const value = event.target.value;
  const regex = /^\d{8,}$/;
  
  if (value.trim() === "") {
    // Si la valeur ne correspond pas, définissez un message d'erreur
    setNumberError("L'adresse doit être un numéro d'au moins 8 chiffres.");
  } else {
    // Sinon, réinitialisez l'erreur et mettez à jour la valeur
    setNumberError("");
    setNumberValue(value);
  }
  };

 
  const cart = JSON.parse(localStorage.getItem("cart"));
  const info = JSON.parse(localStorage.getItem("info"));
  if (cart) {
    const numb = Object.keys(cart).length;
    localStorage.setItem("nbrPanier", JSON.stringify(numb));
  }
  function calculerTotal() {
    if (cart && cart.length > 0) {
      return cart.reduce(
        (acc, article) => acc + article.price * parseInt(article.quantite),
        0
      );
    } else {
      return 0; // Retourne zéro si le panier est vide ou non défini
    }
  }
  function calculerTotalred() {
    return (
      cart.reduce(
        (acc, article) =>
          acc + article.price * parseInt(article.quantite  ),
        0
      ) -
      (5 / 100) *
        cart.reduce(
          (acc, article) =>
            acc + article.price * parseInt(article.quantite ),
          0
        )
    );
  }

  let navigate = useNavigate();

  const [, setCart] = useState(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });
  function deleteArticle(index) {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
    // eslint-disable-next-line eqeqeq
    window.location.reload();
  }

  
  function moin(article, index) {
    const updatedCart = cart.map((item, i) => {
      if (i === index) {
        const newQuantity = parseInt(item.quantite) - 1;
        if (newQuantity <= 0) {
          deleteArticle(index);
        } else {
          item.quantite = newQuantity;
        }
      }
      return item;
    });
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
  }
  async function plus(article) {
    try {
      const response = await Axios.get(`https://ebuy-backend-two.vercel.app/api/article/${article.name}`);
      const articleData = response.data;
      const nouvelArticle = { ...article };
      const updatedCart = cart.map((item) => {
        if (item.name === nouvelArticle.name&&item.quantite<articleData.stock) {
          item.quantite++;
        }
        return item;
      });
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setCart(updatedCart);
    } catch (error) {
      console.error("Une erreur s'est produite lors de la récupération des données de l'article :", error);
    }
  }
  
  const panierQte =
    cart && cart.length > 0
      ? cart.reduce((acc, article) => acc + parseInt(article.quantite), 0)
      : 0;

  async function mettreAJourStockEtLocal() {
    try {
      const updatePromises = cart.map((article) => {
        return Axios.get(`https://ebuy-backend-two.vercel.app/api/article/${article.name}`)
          .then((response) => {
            const articleData = response.data;
            console.log("Données de l'article:", articleData);
            const updatedStock = articleData.stock - article.quantite;
            return Axios.put(`https://ebuy-backend-two.vercel.app/api/article/${articleData._id}`, { stock: updatedStock });
          })
          .then((response) => {
            console.log("Stock mis à jour avec succès:", response.data);
          })
          .catch((error) => {
            console.error("Erreur lors de la mise à jour du stock:", error);
          });
      });
    
    } catch (error) {
      console.error("Une erreur s'est produite lors de la mise à jour du stock et du stockage local :", error);
    }

  }
  function passeCommande() {
    const commande = cart.map((article) => ({
      quantite: article.quantite,
      name: article.name,
      brand: article.brand,
      ref:article.reference,
      color:article.color
    }));
    
    let totalTTC;
    if (
      (code.toUpperCase() === "HELLO-5") &&
      cart.reduce(
        (acc, article) =>
          acc + article.price * parseInt(article.quantite),
        0
      ) -
        (0.05) *
          cart.reduce(
            (acc, article) =>
              acc + article.price * parseInt(article.quantite),
            0
          )
    ) {
      totalTTC = (calculerTotalred() + 8).toFixed(3);
    } else {
      totalTTC = (calculerTotal() + 8).toFixed(3);
    }
    const body = JSON.stringify({
      commande,
      total: totalTTC,
      code,
      nom:nameValue,
      adresse:adressValue,
      tel: NumberValue,
    });
  
    fetch("https://ebuy-backend-two.vercel.app/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Une erreur s'est produite lors de l'envoi de la commande"
          );
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
      
      localStorage.removeItem("info");
      const infoObject = {
        name: nameValue,
        totalTTC: totalTTC,
        date1: formatDate(futureDate1),
        date2: formatDate(futureDate2),
        adresseLivraison: adressValue,
        telephoneLivraison: NumberValue,
        timestamp: new Date().getTime(), // Horodatage de l'enregistrement
      };
      localStorage.setItem("info", JSON.stringify(infoObject));
      localStorage.removeItem("cart");
      
      
  }


      
  

  return (
    <div className="cart">
      {!cart || cart.length === 0 ? (
        infoString ? (
          <div className="panierVide">
            {infoArray &&
              Array.isArray(infoArray) &&
              infoArray.map((infos, index) => (
                <div>
                   <p style={{color:'#F77171',fontWeight:'bold'}}>
                  Votre commande est validée! Merci d'avoir choisi Ebuy. À la prochaine!
                  </p>
                  <p>
                    <Button
                      sx={{ color: "black" }}
                      endIcon={<BeenhereIcon fontSize="small" />}
                    >
                      info livraison :&nbsp;&nbsp;
                    </Button>{" "}
                    <p>
                      <span>{infos.name}</span>
                      {" / "}
                      <span>{infos.adresseLivraison}</span>{" "}
                    </p>
                    <p>
                      <Button
                        sx={{ color: "black" }}
                        startIcon={<PhoneAndroidIcon fontSize="small" />}
                      >
                        {infos.telephoneLivraison}
                      </Button>{" "}
                    </p>
                  </p>
                  <p>
                    <Button
                      sx={{ color: "black" }}
                      startIcon={<LocalShippingIcon />}
                    >
                      Livraison à domicile :&nbsp;&nbsp;
                    </Button>{" "}
                    <span>
                    { /* entre le {infos.date1} et le {infos.date2}*/}
                    </span>{" "}
                  </p>
                  <p style={{display:'flex', flexDirection:'column' , alignItems:'center',justifyContent:'center'}}>
                   
                  Votre colis sera livré par la société de livraison best delivery
                  <img src={deliveryLogo} alt="best delivery" width={'100px'} style={{marginTop:'-10px'}} />
                  </p>
                  <br />
                  <p style={{marginTop:'-10px'}}>
                    <span>
                      <strong>méthode de paiement :</strong> paiement cash à la
                      livraison
                    </span>{" "}
                    <p>
                      <span>
                        <strong>détail de paiement : </strong>
                      </span>{" "}
                      <span>total :{infos.totalTTC} DT </span>{" "}
                    </p>
                  </p>
                </div>
              ))}

            <a href="/collections/electromenager">
              <Button
                endIcon={<ShoppingCartCheckoutIcon />}
                color="warning"
                variant="contained"
                size="small"
                sx={{ marginTop: 2 }}
              >
                continuer VOS ACHATS{" "}
              </Button>
            </a>
          </div>
        ) : (
          <div className="panierVide">
            <h2>Votre Panier est Vide </h2>
            <a href="/collections/electromenager">
              <Button
                endIcon={<ShoppingCartCheckoutIcon />}
                color="warning"
                variant="contained"
                size="small"
                sx={{ marginTop: 2 }}
              >
                COMMENCEZ VOS ACHATS{" "}
              </Button>
            </a>
          </div>
        )
      ) : (
        <div className="cart">
          <div className="panier">
            <h2 className="panierTitle">PANIER {`(${panierQte})`} </h2>
            <ul style={{ width: "90%" }} id="commande">
              {cart.map((article, index) => (
              
                <li key={index} className="allDetail">
                  <div className="deleteDetail">
                    <div className="pictureName">
                      {article.family === "promos" ? (
                        <img
                          src={require(`../../images/promos/${article.img}.jpeg`)}
                          alt="produit"
                          className="prodPic"
                        />
                      ):article.souscategorie? ( <a href={`/produit/${article.family}/${article.categorie}/${article.souscategorie}/${article.name}`}>                        <img
                        src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(article.family)}%2F${decodeURIComponent(article.categorie)}%2F${decodeURIComponent(article.souscategorie)}%2F${decodeURIComponent(article.souscategorie)}%20${decodeURIComponent(article.name)}.jpeg?alt=media`}
                          alt={`${article.name}`}
                          className="prodPic"
                        />
                        </a>):(

                        <a href={`/produit/${article.family}/${article.categorie}/${article.name}`}>                        <img
                        src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(article.family)}%2F${decodeURIComponent(article.categorie)}%2F${decodeURIComponent(article.img)}.jpeg?alt=media`}
                          alt="produit"
                          className="prodPic"
                        />
                        </a>

                      )}

                      <div className="nameBrand">
                        <span>
                          {" "}
                          {parseInt(article.quantite)}* {article.name}
                        </span>
                        <span> {article.brand}</span>
                        {article.color?(<div className="nameBrand" style={{display:'flex',flexDirection:'row'}}> <span>color:</span>  <img
          
            src={require(`../../images/colors/${article.color}.jpeg`)} // Utilisation de .default pour charger l'image
            alt={article.color}
            className={`boisColor`}
          /></div>):(<></>)}
                      </div>
                    </div>
                    <Button
                      color="warning"
                      sx={{ marginTop: "10px" }}
                      endIcon={<DeleteIcon />}
                      onClick={() => deleteArticle(index)}
                    >
                      SUPPRIMER
                    </Button>
                  </div>
                  <div className="prodDetail">
                    <div className="priceDiv">{article.price}&nbsp;TND</div>
                    <div className="artcileDetails">
                      <div className="quantity">
                        <div
                          className="plusmoin"
                          onClick={() => moin(article, index)}
                        >
                          <RemoveCircleIcon
                            color="warning"
                            fontSize="medium"
                            sx={{ cursor: "pointer" }}
                          />
                        </div>
                        <div className="numberArticle">
                          {parseInt(article.quantite)}{" "}
                        </div>
                        <div
                          className="plusmoin"
                          id="plusMoin"
                          onClick={() => plus(article)}
                        >
                          <AddCircleIcon
                            color="warning"
                            fontSize="medium"
                            sx={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="totalDiv">
            <h5>RÉSUMÉ DU PANIER</h5>
            <p id="total">
              <span>Sous-Total TTC:&nbsp;&nbsp;</span>{" "}
              <span>{calculerTotal().toFixed(3)}&nbsp;TND</span>
            </p>

            <p className="livraison">
              <span>frais de livraison:&nbsp;&nbsp;</span>{" "}
              <span>8&nbsp;TND</span>
            </p>
               {/*
            <p className="livraison">
           <span>Code promo:</span>
              <FormControl variant="outlined">
                <InputLabel
                  sx={{
                    marginBottom: 1,
                    fontFamily: "Poppins",
                    fontSize: "14px", // Modifier la taille de la police du champ texte
                    "& .MuiInputLabel-root": {
                      fontSize: "14px", // Modifier la taille de la police du label
                    },
                  }}
                  htmlFor="outlined-adornment-password"
                >
                  tapez le code
                </InputLabel>
                <OutlinedInput
                  sx={{
                    marginBottom: 1,
                    fontFamily: "Poppins",
                    fontSize: "14px", // Modifier la taille de la police du champ texte
                    "& .MuiInputLabel-root": {
                      fontSize: "14px", // Modifier la taille de la police du label
                    },
                  }}
                  id="outlined-adornment-password"
                  size="small"
                  type={"text"}
                  endAdornment={
                    <InputAdornment position="end">
                      <LoyaltyIcon fontSize="small" />
                    </InputAdornment>
                  }
                  label="tapez le code"
                  onChange={(event) => {
                    setCode(event.target.value);
                  }}
                />
              </FormControl>
            
            </p>
            <p style={{textAlign:'center',fontSize:'12px',marginTop:'-5px',color:'#F77171',fontWeight:'bold'}}>Économisez 5% sur votre première commande avec le code HELLO-5</p> */}
            {code.toUpperCase() === "HELLO-5"  &&
            cart.reduce(
              (acc, article) =>
                acc + article.price * parseInt(article.quantite / 2 + 1),
              0
            ) >= 25 ? (
              <>
                <p id="totalTTC" className="totalTTC">
                  <span>Total TTC:&nbsp;&nbsp;</span>{" "}
                  <span>{(calculerTotalred() + 8).toFixed(3)}&nbsp;TND</span>
                </p>
              </>
            ) : (
              <>
                {" "}
                <p id="totalTTC" className="totalTTC">
                  <span>Total TTC:&nbsp;&nbsp;</span>{" "}
                  <span>{(calculerTotal() + 8).toFixed(3)}&nbsp;TND</span>
                </p>
              </>
            )}
            <div className="livraisonDomicile">
              <p >
                <Button
                  sx={{ color: "black" }}
                  startIcon={<LocalShippingIcon />}
                >
                  Livraison à domicile :&nbsp;&nbsp;
                </Button>{" "}
              </p>
              <p style={{marginTop:'-15px'}}>
                <span >
                 {/* entre le {formatDate(futureDate1)} et le{" "}
                  {formatDate(futureDate2)}*/}
                </span>{" "}
                <span>
                <Box sx={{display:'flex',marginTop:'15px'}}>           
                 <PaymentsIcon fontSize="small" />
                  Méthode de paiement: en espèces au moment de la livraison 
                </Box>{" "}
              </span>
              </p>
            </div>
             <form className="validForm" onSubmit={(event) => {
                event.preventDefault(); 
                passeCommande();
                mettreAJourStockEtLocal(); 
            }}>
              <TextField
                autoFocus
                required
                error={nameError !== ""}
                helperText={nameError}
                margin="dense"
                label="Nom et Prenom"
                type="text"
                fullWidth
                variant="standard"
                value={nameValue}
                onChange={handleNameChange}
                InputLabelProps={{
                  sx: { color: "black", fontFamily: "Exo", fontSize: "14px" },
                }}
                InputProps={{
                  sx: { color: "black", fontFamily: "Exo", fontSize: "14px" },
                }}
              />
              <TextField
                autoFocus
                required
                error={nameError !== ""}
                helperText={nameError}
                margin="dense"
                label="Adresse Livraison"
                type="text"
                fullWidth
                variant="standard"
                value={adressValue}
                onChange={handleAdressChange}
                InputLabelProps={{
                  sx: { color: "black", fontFamily: "Exo", fontSize: "14px" },
                }}
                InputProps={{
                  sx: { color: "black", fontFamily: "Exo", fontSize: "14px" },
                }}
              />
         
              <TextField
                autoFocus
                required
                error={numberError !== ""}
                helperText={numberError}
                margin="dense"
                label="Numero Telephone"
                type="number"
                fullWidth
                variant="standard"
                value={NumberValue}
                onChange={handleNumberChange}
                InputLabelProps={{
                  sx: { color: "black", fontFamily: "Exo", fontSize: "14px" },
                }}
                InputProps={{
                  sx: { color: "black", fontFamily: "Exo", fontSize: "14px" },
                }}
              />
                <Button
                  type="submit"
                  variant="contained"
                  color="warning"
                  size="medium"
                  sx={{marginTop:'20px'}}
                >
                  passer la commande
                </Button>
              </form>
            
          </div>
        </div>
      )}
 
    </div>
  );
}
