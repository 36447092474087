/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import React, { useState, useEffect } from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import VerifiedIcon from "@mui/icons-material/VerifiedOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DiscountIcon from "@mui/icons-material/Discount";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import UseFetch from "../../hook/useFetch";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import BestBroduct from "../../Components/ProductRandom";
import ProductBrand from "../../Components/ProductBrand";
import { Dialog, Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function ProductDetails() {
  const { data, loading } = UseFetch(
    "https://ebuy-backend-two.vercel.app/api/article"
  );
  const [userRating, setUserRating] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarer, setOpenSnackbarer] = useState(false);
  const produits = Array.from(data);
  const [openRate, setOpenRate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const user = localStorage.getItem("log");
  const username = localStorage.getItem("name");
  const [couleurSelectionnee, setCouleurSelectionnee] = useState(null);

  const handleClickCouleur = (color) => {
    // Mettre à jour l'état avec la couleur sélectionnée
    setCouleurSelectionnee(color);
  };
  const handleOpenRate = () => {
    setOpenRate(true);
  };
  const handleCloseRate = () => {
    setOpenRate(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleOpenDialog2 = () => {
    setOpenDialog2(true);
  };
  const handleOpenDialog3 = () => {
    setOpenDialog3(true);
  };
  // Fonction pour fermer la boîte de dialogue
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };
  const handleCloseDialog3 = () => {
    setOpenDialog3(false);
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // Vérifie si l'écran est de taille large (lg et supérieur)
  const buttonSize = isLargeScreen ? "medium" : "small";
  const [display, setDisplay] = useState(false);
  function displayDescreption() {
    setDisplay(display === false ? true : false);
  }
  let pathName = window.location.pathname;
  let pathe = pathName.replace(/%20/g, " ");
  let path1 = pathe.split("/")[2];
  let path2 = pathe.split("/")[3];
  let path3 = pathe.split("/")[4];
  let path4 = pathe.split("/")[5];
  const [open, setOpen] = React.useState(false);
  const addToCart = () => {
    const productIndex = produits.findIndex(
      (produit) =>path4? produit.articleName === path4:produit.articleName === path3
    );

    if (productIndex !== -1) {
      // Récupérer les détails du produit à partir de 'produits'
      const product = produits[productIndex];

      // Récupérer le panier depuis le stockage local ou initialiser un nouveau panier s'il est vide
      const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

      const existingProductIndex = existingCart.findIndex(
        (item) =>path4? item.articleName === path4: item.articleName === path3
      );

      if (existingProductIndex !== -1) {
        existingCart[existingProductIndex].quantite += 1;
      } else {
        let price =product.prixFlash?product.prixFlash:product.prix;
      
        existingCart.push({
          family: product.famille,
          name: product.articleName,
          img: `${path2} ${path3}`,
          categorie: product.categorie,
          souscategorie:product.subcategorie,
          price: price.toFixed(3),
          brand: product.brand,
          reference: product.ref,
          quantite: 1,
          color:couleurSelectionnee,
        });
      }

      localStorage.setItem("cart", JSON.stringify(existingCart));

      handleClick();

      window.location.reload();
    }
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const currentProduct = produits.find(
    (produit) =>path4? produit.articleName === path4:produit.articleName === path3
  );
  const sendRatingToAPI = () => {
    fetch(
      `https://ebuy-backend-two.vercel.app/api/article/${currentProduct._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rate: userRating,
          userRate: { username: username, rate: userRating },
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          // Affichez un message de succès

          window.location.reload();
          setOpenSnackbar(true);
        } else {
          setOpenSnackbarer(true);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi du rating :", error);
        // Gérez les erreurs si nécessaire
      });
  };

  useEffect(() => {
    if (userRating > 0) {
      sendRatingToAPI();
    }
  }, [userRating]);
  function sumArrayValues(arr) {
    if (Array.isArray(arr)) {
      return (
        arr.reduce((total, currentValue) => total + currentValue, 0) /
        arr.length
      );
    }
    return 0;
  }

  function lengthUser(arr) {
    if (Array.isArray(arr)) {
      return arr.length;
    }
    return 0;
  }
  const totalRate = currentProduct
    ? sumArrayValues(currentProduct.rate).toFixed(1)
    : 0;
  const totalUser = currentProduct ? lengthUser(currentProduct.userRate) : 0;
  function calculateRatingCounts(ratings) {
    if (!ratings || !Array.isArray(ratings)) {
      return [0, 0, 0, 0, 0];
    }

    const ratingCounts = [0, 0, 0, 0, 0];

    ratings.forEach((rating) => {
      ratingCounts[rating - 1]++;
    });

    return ratingCounts;
  }

  const ratingCounts = currentProduct
    ? calculateRatingCounts(currentProduct.rate)
    : [0, 0, 0, 0, 0];
  return (
    <div className="products details" id="produits">
      <div className="productDetails">
        <span className="navbarCollection details">
          collections
          {path1 && <a href={`/produit/${path1}`}>{` / ${path1}`} </a>}
          {path2 && (
            <a href={`/collections/${path1}/${path2}`}>
              {` / ${decodeURIComponent(path2)}`}{" "}
            </a>
          )}
          {path3 && (
            <a href={`/collections/${path1}/${path2}/${path3}`}>
              {` / ${decodeURIComponent(path3)}`}{" "}
            </a>
          )}
          {path4 && (
            <a href={`/produit/${path1}/${path2}/${path3}/${path4}`}>
              {` / ${decodeURIComponent(path4)}`}{" "}
            </a>
          )}
        </span>
        <ScrollToTop />
        {loading ? (
          <div className="loader details"></div>
        ) : (
          <div className="productContainDetail">
            <h2 className="pathTitle" key={Math.floor(Math.random() * 1)}>
              {path4 ? decodeURIComponent(path4) : decodeURIComponent(path3)}
            </h2>
            <div>
              {produits.map((produit, index) =>
                produit.articleName === decodeURIComponent(path3) ? (
                  <div className="productcardDetails" key={index}>
                    <Carousel
                      showArrows={false}
                      autoPlay={false}
                      showIndicators={false}
                      showStatus={false}
                      className="custom-carousel"
                    >
                      <div onClick={() => handleOpenDialog()}>
                        <img
                          className="cardDetails"
                          src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(
                            path1
                          )}%2F${decodeURIComponent(
                            path2
                          )}%2F${decodeURIComponent(
                            path2
                          )}%20${decodeURIComponent(path3)}.jpeg?alt=media`}
                          title={path3}
                          alt={path3}
                        />
                      </div>
                      <div onClick={() => handleOpenDialog2()}>
                        <img
                          className="cardDetails"
                          src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(
                            path1
                          )}%2F${decodeURIComponent(
                            path2
                          )}%2F${decodeURIComponent(
                            path2
                          )}%20${decodeURIComponent(path3)}-1.jpeg?alt=media`}
                          title={path3}
                          alt={path3}
                        />
                      </div>
                      {produit.photoPlus ? (
                        <div onClick={() => handleOpenDialog3()}>
                          <img
                            className="cardDetails"
                            src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(
                              path1
                            )}%2F${decodeURIComponent(
                              path2
                            )}%2F${decodeURIComponent(
                              path2
                            )}%20${decodeURIComponent(path3)}-2.jpeg?alt=media`}
                            title={path3}
                            alt={path3}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Carousel>
                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      sx={{ zIndex: 9999999 }}
                    >
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(
                          path1
                        )}%2F${decodeURIComponent(
                          path2
                        )}%2F${decodeURIComponent(
                          path2
                        )}%20${decodeURIComponent(path3)}.jpeg?alt=media`}
                        alt={path3}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "80vh",
                          objectFit: "contain",
                          zIndex: 9999999,
                        }}
                      />
                    </Dialog>
                    <Dialog
                      open={openDialog2}
                      onClose={handleCloseDialog2}
                      sx={{ zIndex: 9999999 }}
                    >
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(
                          path1
                        )}%2F${decodeURIComponent(
                          path2
                        )}%2F${decodeURIComponent(
                          path2
                        )}%20${decodeURIComponent(path3)}-1.jpeg?alt=media`}
                        alt={path3}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "80vh",
                          objectFit: "contain",
                          zIndex: 9999999,
                        }}
                      />
                    </Dialog>
                    {produit.photoPlus ? (
                      <Dialog
                        open={openDialog3}
                        onClose={handleCloseDialog3}
                        sx={{ zIndex: 9999999 }}
                      >
                        <img
                          src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(
                            path1
                          )}%2F${decodeURIComponent(
                            path2
                          )}%2F${decodeURIComponent(
                            path2
                          )}%20${decodeURIComponent(path3)}-2.jpeg?alt=media`}
                          alt={path3}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "80vh",
                            objectFit: "contain",
                            zIndex: 9999999,
                          }}
                        />
                      </Dialog>
                    ) : (
                      <></>
                    )}
                    
                    <div className="morecardDetailP">
                      <h2>
                        {produit.articleName} - {produit.brand} {produit.ref}
                      </h2>
                    {produit.prixFlash ? (
                        <>
                          <h4
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "red",
                              textDecorationThickness: "2px",
                              color: "black",
                            }}
                          >
                            {produit.prix.toFixed(0)} TND
                          </h4>
                          <h4 style={{ marginTop: "-10px" }}>
                            {produit.prixFlash.toFixed(0)} TND <br />
                            <span className="morecardDetailPrice">
                              {" "}
                              + frais de livraison 8 TND sur toute La Tunisie{" "}
                            </span>
                          </h4>
                        </>
                      ) : (
                        <>
                          <h4>
                            {produit.prix.toFixed(0)} TND <br />
                            <span className="morecardDetailPrice">
                              {" "}
                              + frais de livraison 8 TND sur toute La Tunisie{" "}
                            </span>
                          </h4>
                        </>
                      )}
                      {produit.colors?(
                        <>
                     {produit.colors && produit.colors.length > 0 ? (
    <span className="morecardDetailColor">Sélectionnez la texture de votre mobilier</span>
) : (
    <></>
)}
      <div className="colorboisDiv">
        {produit.colors.map((color, index) => (
          
          <img
            key={index}
            src={require(`../../images/colors/${color}.jpeg`)} // Utilisation de .default pour charger l'image
            alt={color}
            className={`boisColor ${color === couleurSelectionnee ? 'selected' : ''}`}
            onClick={() => handleClickCouleur(color)}
          />
        ))}
      </div></>):(<></>)}
                      {/*
                    <Box display="flex">
                      <Rating
                        name="size-medium"
                        defaultValue={totalRate}
                        size="medium"
                        max={5}
                        readOnly
                      />
                      <span
                        style={{ cursor: "pointer" }}
                        className="avis"
                        onClick={() => handleOpenRate()}
                      >
                        {" "}
                        ( {totalUser} avis )
                      </span>
                      <React.Fragment>
                        <Dialog
                          sx={{ zIndex: 99999999999999 }}
                          open={openRate}
                          onClose={handleCloseRate}
                        >
                          {user ? (
                            <div className="DialogDivRate">
                              <Box
                                sx={{
                                  width: "60%",
                                  marginLeft: "20%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border: "gold 1px solid",
                                }}
                              >
                                <span
                                  className="avis"
                                  onClick={() => handleOpenRate()}
                                >
                                  {" "}
                                  <Button
                                    color="warning"
                                    endIcon={<VerifiedIcon fontSize="small" />}
                                  >
                                    {" "}
                                    AVIS VÉRIFIÉS ( {totalUser} )
                                  </Button>{" "}
                                </span>
                                <span
                                  className="avis"
                                  onClick={() => handleOpenRate()}
                                >
                                  {" "}
                                  {totalRate} / 5{" "}
                                </span>
                                <Rating
                                  name="size-medium"
                                  defaultValue={totalRate}
                                  size="medium"
                                  max={5}
                                  readOnly
                                />
                              </Box>
                              <ul>
                                {[1, 2, 3, 4, 5].map((rating, index) => (
                                  <li key={index}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {rating}{" "}
                                      <Rating
                                        name="size-small"
                                        value={rating}
                                        max={1}
                                        readOnly
                                        sx={{ marginLeft: "5px" }}
                                      />
                                      <span style={{ marginLeft: "10px" }}>
                                        ({ratingCounts[index]} avis)
                                      </span>
                                    </Box>
                                  </li>
                                ))}
                              </ul>
                              <span className="avis">
                                Notez{" "}
                                <span
                                  style={{
                                    color: "#F77171",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  {produit.articleName}{" "}
                                </span>
                                <br />
                                <Rating
                                  name="user-rating"
                                  value={userRating}
                                  onChange={(event, newValue) =>
                                    setUserRating(newValue)
                                  }
                                />
                              </span>
                              <Snackbar
                                open={openSnackbar}
                                autoHideDuration={6000}
                                onClose={() => setOpenSnackbar(false)}
                              >
                                <Alert
                                  onClose={() => setOpenSnackbar(false)}
                                  severity="success"
                                >
                                  Votre évaluation a été enregistrée avec succès
                                  !
                                </Alert>
                              </Snackbar>
                              <Snackbar
                                open={openSnackbarer}
                                autoHideDuration={6000}
                                onClose={() => setOpenSnackbarer(false)}
                              >
                                <Alert
                                  onClose={() => setOpenSnackbarer(false)}
                                  severity="error"
                                >
                                  vous aver déja évaluez !
                                </Alert>
                              </Snackbar>
                            </div>
                          ) : (
                            <div className="DialogDivRate">
                              <Box
                                sx={{
                                  width: "50%",
                                  marginLeft: "25%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border: "gold 1px solid",
                                }}
                              >
                                <span
                                  className="avis"
                                  onClick={() => handleOpenRate()}
                                >
                                  {" "}
                                  <Button
                                    color="warning"
                                    endIcon={<VerifiedIcon fontSize="small" />}
                                  >
                                    {" "}
                                    AVIS VÉRIFIÉS ( {totalUser} )
                                  </Button>{" "}
                                </span>
                                <span
                                  className="avis"
                                  onClick={() => handleOpenRate()}
                                >
                                  {" "}
                                  {totalRate} / 5{" "}
                                </span>
                                <Rating
                                  name="size-medium"
                                  defaultValue={totalRate}
                                  size="medium"
                                  max={5}
                                  readOnly
                                />
                              </Box>
                              <ul>
                                {[1, 2, 3, 4, 5].map((rating, index) => (
                                  <li key={index}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {rating}{" "}
                                      <Rating
                                        name="size-small"
                                        value={rating}
                                        max={1}
                                        readOnly
                                        sx={{ marginLeft: "5px" }}
                                      />
                                      <span style={{ marginLeft: "10px" }}>
                                        ({ratingCounts[index]} avis)
                                      </span>
                                    </Box>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </Dialog>
                      </React.Fragment>
                    </Box>
                    */}
                      <Button
                        sx={{
                          marginTop: 5,
                          fontFamily: "Exo",
                          fontSize: {
                            lg: "14px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                        onClick={displayDescreption}
                        variant="contained"
                        size={buttonSize}
                        color="warning"
                        fullWidth
                        endIcon={<VisibilityIcon />}
                      >
                        Voir le descriptif technique
                      </Button>
                      {display ? (
                        <p>
                          {produit.descreption}
                          <br />
                          {produit.categorie !== "mobilier" ? (
                            <strong> garantie: 1 ans</strong>
                          ) : (
                            <></>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}
                      {produit.stock < 1 ? (
                        <Button
                          sx={{
                            marginTop: 5,
                            fontFamily: "Exo",
                            fontSize: {
                              lg: "14px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                          }}
                          variant="contained"
                          color="warning"
                          size={buttonSize}
                          fullWidth
                          endIcon={<LocalShippingIcon />}
                        >
                          EN ARRIVAGE
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            marginTop: 5,
                            fontFamily: "Exo",
                            fontSize: {
                              lg: "14px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                          }}
                          variant="contained"
                          color="warning"
                          size={buttonSize}
                          fullWidth
                          startIcon={<ShoppingCartIcon />}
                          titleAccess={`Ajouter ${produit.articleName} au panier`}
                          onClick={addToCart}
                        >
                          J'achète
                        </Button>
                      )}
                      <div className="profitDetail">
                     {/*    <span className="profitDetailspan">Profitez!</span>
                          <Box sx={{ display: "flex", marginTop: "10px" }}>
                       <DiscountIcon fontSize="small" color="warning" />
                          <span
                            className="profitDetailspan"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                            Nouveaux clients? : profitez de 5% de remise avec le
                            code{" "}
                            <span style={{ color: "#F77171", fontWeight: 800 }}>
                              HELLO-5
                            </span>{" "}
                          </span>
                        </Box>*/}
                        <Box sx={{ display: "flex", marginTop: "10px" }}>
                          <PermContactCalendarIcon
                            fontSize="small"
                            color="warning"
                          />
                          <span
                            className="profitDetailspan"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                            Pour plus d'informations! : appelez{" "}
                            <a
                              href="tel:26380004"
                              className="profitDetailspan info"
                            >
                              26 380 004{" "}
                            </a>{" "}
                          </span>
                        </Box>
                      </div>
                      <Snackbar
                        open={open}
                        autoHideDuration={5000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {produit.articleName} a été ajouté au panier !
                        </Alert>
                      </Snackbar>
                    </div>
                    {/*--------------------------------------------------------*/}
                  </div>
                ) : produit.articleName === decodeURIComponent(path4) ? (
                  <div className="productcardDetails" key={index}>
                    <Carousel
                      showArrows={false}
                      autoPlay={false}
                      showIndicators={false}
                      showStatus={false}
                      className="custom-carousel"
                    >
                      <div onClick={() => handleOpenDialog()}>
                        <img
                          className="cardDetails"
                          src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(path1)}%2F${decodeURIComponent(path2)}%2F${decodeURIComponent(path3)}%2F${decodeURIComponent(path3)}%20${decodeURIComponent(path4)}.jpeg?alt=media`}
                          title={path4}
                          alt={path4}
                        />
                      </div>
                      <div onClick={() => handleOpenDialog2()}>
                        <img
                          className="cardDetails"
                          src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(path1)}%2F${decodeURIComponent(path2)}%2F${decodeURIComponent(path3)}%2F${decodeURIComponent(path3)}%20${decodeURIComponent(path4)}-1.jpeg?alt=media`}
                          title={path4}
                          alt={path4}
                        />
                      </div>
                      {produit.photoPlus ? (
                    
                        <div onClick={() => handleOpenDialog3()}>
                         <img
                          className="cardDetails"
                          src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(path1)}%2F${decodeURIComponent(path2)}%2F${decodeURIComponent(path3)}%2F${decodeURIComponent(path3)}%20${decodeURIComponent(path4)}-2.jpeg?alt=media`}
                          title={path4}
                          alt={path4}
                        />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Carousel>
                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      sx={{ zIndex: 999999999999999999999999 }}
                    >
                      <img
                      className="cardDetails"
                      src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(path1)}%2F${decodeURIComponent(path2)}%2F${decodeURIComponent(path3)}%2F${decodeURIComponent(path3)}%20${decodeURIComponent(path4)}.jpeg?alt=media`}
                      title={path4}
                      alt={path4}

                        style={{
                          maxWidth: "100%",
                          maxHeight: "70vh",
                          objectFit: "contain",
                          zIndex: 9999999999999999999999999999999999999999999,
                        }}
                      />
                    </Dialog>
                    <Dialog
                      open={openDialog2}
                      onClose={handleCloseDialog2}
                      sx={{ zIndex: 9999999 }}
                    >
                      <img
                         src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(path1)}%2F${decodeURIComponent(path2)}%2F${decodeURIComponent(path3)}%2F${decodeURIComponent(path3)}%20${decodeURIComponent(path4)}-1.jpeg?alt=media`}
                        alt={path3}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "70vh",
                          objectFit: "contain",
                          zIndex: 9999999,
                        }}
                      />
                    </Dialog>
                    {produit.photoPlus ? (
                      <Dialog
                        open={openDialog3}
                        onClose={handleCloseDialog3}
                        sx={{ zIndex: 9999999 }}
                      >
                        <img
                           src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(path1)}%2F${decodeURIComponent(path2)}%2F${decodeURIComponent(path3)}%2F${decodeURIComponent(path3)}%20${decodeURIComponent(path4)}-2.jpeg?alt=media`}
                          alt={path3}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "70vh",
                            objectFit: "contain",
                            zIndex: 9999999,
                          }}
                        />
                      </Dialog>
                    ) : (
                      <></>
                    )}
                    <div className="morecardDetailP">
                      <h2>
                        {produit.articleName} - {produit.brand} {produit.ref}
                      </h2>
                    { produit.prixFlash ? (
                        <>
                          <h4
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "red",
                              textDecorationThickness: "2px",
                              color: "black",
                            }}
                          >
                            {produit.prixFlash.toFixed(0)} TND
                          </h4>
                          <h4 style={{ marginTop: "-10px" }}>
                            {produit.prix.toFixed(0)} TND <br />
                            <span className="morecardDetailPrice">
                              {" "}
                              + frais de livraison 8 TND sur toute La Tunisie{" "}
                            </span>
                          </h4>
                        </>
                      ) : (
                        <>
                          <h4>
                            {produit.prix.toFixed(0)} TND <br />
                            <span className="morecardDetailPrice">
                              {" "}
                              + frais de livraison 8 TND sur toute La Tunisie{" "}
                            </span>
                          </h4>
                        </>
                      )}
                     
                      <Button
                        sx={{
                          marginTop: 5,
                          fontFamily: "Exo",
                          fontSize: {
                            lg: "14px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                        onClick={displayDescreption}
                        variant="contained"
                        size={buttonSize}
                        color="warning"
                        fullWidth
                        endIcon={<VisibilityIcon />}
                      >
                        Voir le descriptif technique
                      </Button>
                      {display ? (
                        <p>
                          {produit.descreption}
                          <br />
                          {produit.categorie !== "mobilier" ? (
                            <strong> garantie: 1 ans</strong>
                          ) : (
                            <></>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}
                      {produit.stock < 1 ? (
                        <Button
                          sx={{
                            marginTop: 5,
                            fontFamily: "Exo",
                            fontSize: {
                              lg: "14px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                          }}
                          variant="contained"
                          color="warning"
                          size={buttonSize}
                          fullWidth
                          endIcon={<LocalShippingIcon />}
                        >
                          EN ARRIVAGE
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            marginTop: 5,
                            fontFamily: "Exo",
                            fontSize: {
                              lg: "14px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                          }}
                          variant="contained"
                          color="warning"
                          size={buttonSize}
                          fullWidth
                          startIcon={<ShoppingCartIcon />}
                          titleAccess={`Ajouter ${produit.articleName} au panier`}
                          onClick={addToCart}
                        >
                          J'achète
                        </Button>
                      )}
                      <div className="profitDetail">
                     {/*   <span className="profitDetailspan">Profitez!</span>
                        <Box sx={{ display: "flex", marginTop: "10px" }}>
                          <DiscountIcon fontSize="small" color="warning" />
                          <span
                            className="profitDetailspan"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                            Nouveaux clients? : profitez de 5% de remise avec le
                            code{" "}
                            <span style={{ color: "#F77171", fontWeight: 800 }}>
                              HELLO-5
                            </span>{" "}
                          </span>
                        </Box>*/}
                        <Box sx={{ display: "flex", marginTop: "10px" }}>
                          <PermContactCalendarIcon
                            fontSize="small"
                            color="warning"
                          />
                          <span
                            className="profitDetailspan"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                            Pour plus d'informations! : appelez{" "}
                            <a
                              href="tel:26380004"
                              className="profitDetailspan info"
                            >
                              26 380 004{" "}
                            </a>{" "}
                          </span>
                        </Box>
                      </div>
                      <Snackbar
                        open={open}
                        autoHideDuration={5000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {produit.articleName} a été ajouté au panier !
                        </Alert>
                      </Snackbar>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              )}
              {/*--------------------------------------------------------*/}
            </div>
          </div>
        )}
      </div>
      <div className="bestProductDiv">
        <h2>Notre sélection pour vous </h2>
        <BestBroduct categName={path2} currentProduct={path3} />
        <h2>
          autres produits de la marque{" "}
          <span style={{ color: "#F77171" }}>
            {currentProduct ? currentProduct.brand : null}
          </span>{" "}
        </h2>
        <ProductBrand
          currentProduct={path3}
          brand={currentProduct ? currentProduct.brand : null}
        />
      </div>
      <Footer />
    </div>
  );
}
