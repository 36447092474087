import Footer from "../Footer";
import "./style.css";
import quinous from '../../images/qui-nous.jpg'
function SommeNous() {


    return (
        <div className="">
            <img src={quinous} alt="banniere" className="banniereNous" />
            <div className="sommeNousDiv">
            <h3>Bienvenue sur eBuy : Explorez, Commandez, Profitez !</h3>

<p>Chez eBuy, nous croyons que le shopping en ligne devrait être une expérience sans tracas, où vous pouvez trouver tout ce dont vous avez besoin, du confort de votre foyer. Nous sommes fiers de vous présenter une vaste gamme de produits, allant de l'électroménager au mobilier, en passant par les luminaires, le tout à portée de clic.</p>


<h3>Une sélection incomparable</h3>
<p>Parcourez notre catalogue en ligne soigneusement sélectionné pour découvrir une variété de produits de qualité, conçus pour répondre à vos besoins quotidiens. Que vous recherchiez des appareils électroménagers de pointe pour faciliter votre quotidien, des meubles élégants pour transformer votre espace de vie, ou des luminaires pour illuminer votre intérieur avec style, eBuy est là pour vous.</p>

<h3>Commande en ligne simplifiée</h3>
<p>Avec notre plateforme conviviale, passer une commande n'a jamais été aussi simple. Parcourez nos catégories, consultez les descriptions détaillées , puis ajoutez vos articles préférés à votre panier en un seul clic. </p>

<h3>Livraison directe à votre porte</h3>
<p>Dites adieu aux tracas liés au transport. Avec eBuy, nous vous apportons vos achats directement à votre porte, où que vous soyez.</p>
</div>
        <Footer/>
        </div>
    );
}

export default SommeNous;
