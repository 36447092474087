import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import './style.css'
import ban1 from "../../images/banniere/bannier1.jpg"
import { Button } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useInView } from "framer-motion";
import { useRef } from "react";
const theme = createTheme({
  palette: {
    primary: {
       
            main: '#fff',
            dark: '#fEE',
            contrastText: '#FFF',
      
    },
  },
});
export default function BanniereHome() {
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
      return(
        <ThemeProvider theme={theme}>
        <div className="banniereHome" ref={ref2} style={{ 
            transform: isInView2 ? "none" : "translateY(200px)",
            opacity: isInView2 ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}>
          <div>
          <img src={ban1} alt='montres'/>
          <a href='collections/mode%20&%20accessoires/accessoires'>
          <Button size='small' endIcon={<KeyboardArrowRightIcon fontSize='small'/>}  sx={{position:'absolute',right:'1px',marginTop:'-5px',fontSize:{lg:'16px',sm:'14px',xs:'8px'},fontFamily:'Exo' }} >Voir plus</Button>
          </a>
          </div>
  </div>
</ThemeProvider>
)
}