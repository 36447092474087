/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import Footer from '../../Components/Footer';
import React, { useEffect } from 'react';
import anime from 'animejs';

export default function NosMarques() {
  useEffect(() => {
    anime({
      targets: ".refImg",
      translateX: [400, 0],
      opacity: [0, 1],
      scale: [0, 1],
      delay: anime.stagger(200, { start: 300 }),
      easing: "easeInOutQuad",
    });

  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll(".refImg:not(.vide)");
    anime({
      targets: elements,
      translateX: [400, 0],
      opacity: [0, 1],
      scale: [0, 1],
      delay: anime.stagger(300, { start: 400 }),
      easing: "easeInOutQuad",
    });
  }, []);

  return (
    <div>
      <div className='ourBrand'>
        <h2>Nos Marques</h2>
        <p>Notre sélection minutieuse de marques dans les domaines de l'électroménager, du mobilier et des luminaires vous offre la garantie de qualité et le style qui définit votre espace.<br/> 
        Trouvez la perfection parmi les marques que nous avons soigneusement réunies pour vous</p>
        <div className='refGrid'>
          {itemData.map((item) => (
            <a href={`/${item.toLocaleUpperCase()}`} key={item}>
              <div className={`image-zoom-container ${item === 'EMUCA' ? 'hiddenOnLargeScreen' : ''}`}>
                <img
                  src={require(`../../images/logo/${item}.png`)}
                  alt={item}
                  className='refImg'
                />
              </div>
            </a>
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

const itemData = ['RUSSELL HOBBS','UFESA','TELEFUNKEN','ELEMENTS','PHILIPS','FLUA','EGLO','EMUCA','BLACK & DECKER','WAHL','REMINGTON','MICHAEL KORS','Q&Q','LEIFHEIT','DI4','SOHENLE'];
