import React, { useState, useEffect } from "react";
import ProductCard from "../ProductCard";
import { Button, Pagination } from "@mui/material";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import UseFetch from "../../hook/useFetch";
import { useLocation } from "react-router";
import './style.css'
export default function SearchResults() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [sortBy, setSortBy] = useState("nameAsc");
  const { data, loading } = UseFetch("https://ebuy-backend-two.vercel.app/api/article");
  const produits = Array.from(data);

  const query = new URLSearchParams(location.search).get('query')?.toLowerCase() || '';

  const handleSortToggle = () => {
    setSortBy(sortBy === 'priceAsc' ? 'priceDesc' : 'priceAsc');
  };

  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(15);
      } else if (windowWidth >= 700) {
        setItemsPerPage(15);
      } else {
        setItemsPerPage(16);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page')) || 1;
    setCurrentPage(page);
  }, [location.search]);

  const filteredProducts = produits.filter((produit) => {
    return produit.articleName.toLowerCase().includes(query) ||
           produit.brand.toLowerCase().includes(query) ||
           produit.ref.toLowerCase().includes(query) ||
           produit.categorie.toLowerCase().includes(query) ||
           produit.famille.toLowerCase().includes(query);
  });

  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  let sortedProducts = [...filteredProducts];
  if (sortBy === "priceAsc") {
    sortedProducts.sort((a, b) => a.prix - b.prix);
  } else if (sortBy === "priceDesc") {
    sortedProducts.sort((a, b) => b.prix - a.prix);
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', newPage.toString());
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const currentCards = sortedProducts.slice(indexOfFirstCard, indexOfLastCard);

  return loading ? (
    <div className="loader"></div>
  ) : (
    <div>
      <div className="triDivSearch">
        <Button
          sx={{ fontFamily: 'Poppins', fontSize: { lg: '12px', md: '12px', sm: '12px', xs: '10px' } }}
          size="small"
          variant="outlined"
          color="warning"
          onClick={handleSortToggle}
          endIcon={<SwapVertIcon />}
        >
          Prix
        </Button>
      </div>
      <div className="allProductsearch">
        {currentCards.map((produit, index) => (
          <ProductCard
            key={produit.article + index}
            categorie={produit.categorie}
            subcategorie={produit.subcategorie}
            article={produit.article}
            prix={produit.prix}
            prixFlash={produit.prixFlash}
            brand={produit.brand}
            famille={produit.famille}
            reference={produit.ref}
            stock={produit.stock}
          />
        ))}
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            shape="rounded"
            variant="outlined"
            onChange={handlePageChange}
            color="warning"
            size="small"
          />
        </div>
      )}
    </div>
  );
}
