import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewProductHome from "../NewProductHome";
import UseFetch from "../../hook/useFetch";
import { useInView } from "framer-motion";
import { useRef } from "react";
export default function NewHome() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
    const { data } = UseFetch("https://ebuy-backend-two.vercel.app/api/article");
    const produits = Array.isArray(data) ? data.slice(-20).reverse() : [];
    useEffect(() => {
        const updateItemsPerPage1 = () => {
          const windowWidth = window.innerWidth;
          if (windowWidth >= 1200) {
            setItemsPerPage1(6);
          } else if (windowWidth >= 700) {
            setItemsPerPage1(4);
          } else {
            setItemsPerPage1(3);
          }
        };
        updateItemsPerPage1();
        window.addEventListener("resize", updateItemsPerPage1);
    
        return () => {
          window.removeEventListener("resize", updateItemsPerPage1);
        };
      }, []);
    const [itemsPerPage1, setItemsPerPage1] = useState(6);
    const settings1 = {
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 500,
        slidesToShow: itemsPerPage1,
        slidesToScroll: 1,
        className: "custom-slider",
      };
      return(
        <div className="slideNew" ref={ref} style={{ 
          transform: isInView ? "none" : "translateY(200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>
        <h2 className="bigTitleSlide">Nouveautés</h2>
        <Slider {...settings1}>
          {produits.map((produit) => (
            <div
              className={`images-zoom-container `}
              style={{ backgroundColor: "#283943" }}
            >
              <div className="promoContainer">
                <NewProductHome
                  key={produit.article}
                  categorie={produit.categorie}
                  subcategorie={produit.subcategorie}
                  article={produit.article}
                  prix={produit.prix}
                  colors={produit.colors}
                  brand={produit.brand}
                  famille={produit.famille}
                  reference={produit.ref}
                  stock={produit.stock}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
     
)
}
