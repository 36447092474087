import React, { useState, useEffect } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import './style.css';

export default function ScrollPage() {
  const [showButton, setShowButton] = useState(false);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    function handleScroll() {
      setShowButton(window.scrollY >= window.innerHeight-100);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className={showButton ? 'topBtn' : 'topBtn hidden'}>
      <KeyboardDoubleArrowUpIcon onClick={topFunction} sx={{ fontSize: { lg: '28px' } }} color='warning'/>
    </div>
  );
}
