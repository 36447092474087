/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import ScrollToTop from '../../Components/scrollup';
import SaisonProduct from '../../Components/SaisonProduct';
import summer from '../../images/summer.jpeg'
export default function ProductOfSeason() {

  return (
    <div className='products100' id='produits'>
       <img
            src={summer}
            alt='summer'
            className='vente100Img'
          />
      <div className='product100'>
        <ScrollToTop/>
       
        <div className='product100Contain'>
       
          <SaisonProduct saison='summer' />
        </div>
      </div>
      <Footer/>
    </div>
  );
}
