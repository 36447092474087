import Slider from "react-slick";
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ban1 from "../../images/slide/ban1.jpeg";
import ban2 from "../../images/slide/ban2.jpeg";
import ban3 from "../../images/slide/ban3.jpeg";
import { useInView } from "framer-motion";
import { useRef } from "react";
export default function SelctionSemaine() {
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
    const shouldEnableSlider = window.innerWidth < 700;
    const settings2 = {
        autoplay: false,
        autoplaySpeed: 2000,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      return(
        <div ref={ref2} style={{ 
          transform: isInView2 ? "none" : "translateY(200px)",
          opacity: isInView2 ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>
            {shouldEnableSlider && (
    <Slider {...settings2}>
      <div className="categorie">
        <div className="carousselDivHome">
          <div className="carouselDivH">
            <a
              href="/produit/mobilier/mobilier/meuble%20de%20rangement"
              target="_blank"
            >
              <img src={ban1} alt="weeklypromo" className="banPromoPic" />
            </a>
            <div className="carouselDivV">
              <a
                href="/produit/mode%20&%20accessoires/accessoires/montres%20femmes/montre%20femme%20VS68J007Y"
                target="_blank"
              >
                <img
                  src={ban2}
                  alt="weeklypromo"
                  className="banPromoPic2"
                />
              </a>
              <a
                href="bons-plans/kit%20soin%20complet"
                target="_blank"
              >
                <img
                  src={ban3}
                  alt="weeklypromo"
                  className="banPromoPic2"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  )}
  {!shouldEnableSlider && (
    <div className="categorie">
      <div className="carousselDivHome">
        <div className="carouselDivH">
          <a
            href="/produit/mobilier/mobilier/meuble%20de%20rangement"
            target="_blank"
          >
            <img src={ban1} alt="weeklypromo" className="banPromoPic" />
          </a>
          <div className="carouselDivV">
            <a
              href="/produit/mode%20&%20accessoires/accessoires/montres%20femmes/montre%20femme%20VS68J007Y"
              target="_blank"
            >
              <img
                src={ban2}
                alt="weeklypromo"
                className="banPromoPic2"
              />
            </a>
            <a
              href="/bons-plans/kit%20soin%20complet"
              target="_blank"
            >
              <img
                src={ban3}
                alt="weeklypromo"
                className="banPromoPic2"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )}
  </div>

)
}