import UseFetch from "../../hook/useFetch";
import { List, ListItem } from '@mui/material';
import "./style.css";

function CustomList(props) {
    const {data}=UseFetch("https://ebuy-backend-two.vercel.app/api/article");
    const produits = Array.from(data);
    const filteredData = produits.filter((el) => {
        const searchInput = props.input.toLowerCase();
        if (searchInput.length >= 2) {
            return el.articleName.toLowerCase().includes(searchInput) ||
                   el.brand.toLowerCase().includes(searchInput) ||
                   el.ref.toLowerCase().includes(searchInput) ||
                   el.categorie.toLowerCase().includes(searchInput) ||
                   el.famille.toLowerCase().includes(searchInput);
        } else {
            return true;
        }
    });
    

    return (
        <div className="listOfArticle">
            {props.input.length >= 1 && (
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 400,
                        '& ul': { padding: 0 },
                    }}
                    subheader={<li />}
                >
                    {filteredData.length > 4 ? (
                        filteredData.map((item) => (
                            (!item.subcategorie)?(
                            <ListItem key={item.id} button component="a" href={`/produit/${item.famille}/${item.categorie}/${item.articleName}`}>
                                <h3 className="liArticle">
                              
                                    <img
                                    src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${encodeURIComponent(item.famille)}%2F${encodeURIComponent(item.categorie)}%2F${item.article.replace(/-/g, ' ')}.jpeg?alt=media`}
                               
                                        alt="produit"
                                        className="prodPicList"
                                    />
                              
                                    <div className="listDetails">
                                        <span>{item.articleName} - {item.brand}</span>
                                     {item.prixFlash?( <span style={{color:'#F77171'}}>{item.prixFlash} TND</span>):(
                                         <span style={{color:'#F77171'}}>{item.prix} TND</span>)}
                                    </div>
                                </h3>
                            </ListItem>  ):(
                                <ListItem key={item.id} button component="a" href={`/produit/${item.famille}/${item.categorie}/${item.subcategorie}/${item.articleName}`}>
                                <h3 className="liArticle">
                              
                                <img
                                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${encodeURIComponent(item.famille)}%2F${encodeURIComponent(item.categorie)}%2F${encodeURIComponent(item.subcategorie)}%2F${item.article.replace(/-/g, ' ')}.jpeg?alt=media`}
                           
                                    alt="produit"
                                    className="prodPicList"
                                />
                                <div className="listDetails">
                                <span>{item.articleName} - {item.brand}</span>
                              
                                {item.prixFlash?( <span style={{color:'#F77171'}}>{item.prixFlash} TND</span>):(
                                         <span style={{color:'#F77171'}}>{item.prix} TND</span>)}
                            </div>
                        </h3>
                    </ListItem>)
                        ))
                    ) : (
                        filteredData.map((item) => (
                            !item.subcategorie?(
                            <a className="listLink" href={`/produit/${item.famille}/${item.categorie}/${item.articleName}`} key={item.id}>
                                <h3 className="liArticle">
                               
                                    <img
                                    src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${encodeURIComponent(item.famille)}%2F${encodeURIComponent(item.categorie)}%2F${item.article.replace(/-/g, ' ')}.jpeg?alt=media`}
                               
                                        alt="produit"
                                        className="prodPicList"
                                    />
                             
                                    <div className="listDetails">
                                        <span>{item.articleName} - {item.brand}</span>
                                      
                                         <span style={{color:'#F77171'}}>{item.prix} TND</span>
                                    </div>
                                </h3>
                            </a>   ):(
                                    <a className="listLink" href={`/produit/${item.famille}/${item.categorie}/${item.subcategorie}/${item.articleName}`} key={item.id}>
                                <h3 className="liArticle">
                                    <img
                                    src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${encodeURIComponent(item.famille)}%2F${encodeURIComponent(item.categorie)}%2F${encodeURIComponent(item.subcategorie)}%2F${item.article.replace(/-/g, ' ')}.jpeg?alt=media`}
                               
                                        alt="produit"
                                        className="prodPicList"
                                    />
                                    
                                    <div className="listDetails">
                                        <span>{item.articleName} - {item.brand}</span>
                                     
                                        {item.prixFlash?( <span style={{color:'#F77171'}}>{item.prixFlash} TND</span>):(
                                         <span style={{color:'#F77171'}}>{item.prix} TND</span>)}
                                    </div>
                                    </h3>
                            </a>   )
                        ))
                    )}
                </List>
            )}
        </div>
    );
}

export default CustomList;
