import Header from './Components/Header';
import Home from './Pages/Home';
import Products from './Pages/Products'
import ProductDetails from './Pages/ProductDetails';
import Panier from './Components/panier';
import SommeNous from './Components/Sommes-nous';
import NosMarques from './Pages/Nos marques';
import ProductsMarques from './Pages/ProductsMarques';
import Promos from './Pages/Promos';
import PromoDetails from './Pages/PromoDetails';
import Politiques from './Components/Politiques';
import SearchResults from './Components/ProductSearch';
import Product100 from './Pages/Product100';
import Product50 from './Pages/Product50';
import VenteFlashPage from './Pages/VenteFlashPage';
import ProductOfSeason from './Pages/ProductOfSeason';
import { BrowserRouter,Route,Routes } from 'react-router-dom';


import ReactGA from "react-ga";
const track_id="G-T6622GHTPT";

function App() {
  
  
  
  
  

  ReactGA.initialize(track_id);
  ReactGA.pageview(window.location.pathname);
  return (
    <div className="App">
        <BrowserRouter>
      <Header/>
      <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path="/collections" element={<Products/>}/>
          <Route path="/:name/:name" element={<Products/>}/>
          <Route path="/collections/:name/:name" element={<Products/>}/>
          <Route path="/collections/:name/:name/:name" element={<Products/>}/>
          <Route path="/produit/:name/:name/:name" element={<ProductDetails/>}/>
          <Route path="/produit/:name/:name/:name/:name" element={<ProductDetails/>}/>
          <Route path="/bons-plans/:name" element={<PromoDetails/>}/>
          <Route path='/panier' element={<Panier/>}/>
          <Route path='/qui-sommes-nous' element={<SommeNous/>}/>
          <Route path='/politique-de-confidentialite' element={<Politiques/>}/>
          <Route path='/nos-marques' element={<NosMarques/>}/>
          <Route path="/:name" element={<ProductsMarques/>}/>
          <Route path='/bons-plans' element={<Promos/>}/>
          <Route path="/-80" element={<Product50/>}/>
          <Route path="/-150" element={<Product100/>}/>
          <Route path="/venteflash" element={<VenteFlashPage/>}/>
          <Route path="/saison" element={<ProductOfSeason/>}/>
          <Route path="/search" element={<SearchResults/>}/>
         </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
