/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import AllProducts from '../../Components/allProducts';
import ScrollToTop from '../../Components/scrollup';
import ProductNav from '../../Components/productNav';
import ScrollPage from '../../Components/scrollpageUp'
export default function Products() {
  const [path1, setPath1] = React.useState('');
  const [path2, setPath2] = React.useState('');
  const [path3, setPath3] = React.useState('');
  const [path4, setPath4] = React.useState('');
  
  React.useEffect(() => {
    let pathName = window.location.pathname;
    let pathe = pathName.replace(/%20/g, ' ');
    let newPath1 = pathe.split('/')[2];
    let newPath2 = pathe.split('/')[3];
    let newPath3 = pathe.split('/')[4];
    let newPath4 = pathe.split('/')[5];
    setPath1(newPath1);
    setPath2(newPath2);
    setPath3(newPath3);
    setPath4(newPath4);
  }, []);

  return (
    <div className='products' id='produits'>
      <ScrollPage/>
      <div className='product'>
        <ScrollToTop/>
        <div className='nav'>
          <ProductNav/>
        </div>
        <div className='productContain'>
          {path4 ? (
            <h2 className='pathTitle'>{decodeURIComponent(path4)}</h2>
          ) : 
          path3 ? (
            <h2 className='pathTitle'>{decodeURIComponent(path3)}</h2>
          ) : path2 ? (
            <h2 className='pathTitle'>{decodeURIComponent(path2)}</h2>
          ) : (
            <h2 className='pathTitle'>{decodeURIComponent(path1)}</h2>
          )}
          <AllProducts souscategName={path3}  categName={path2} familyName={decodeURIComponent(path1)} />
        </div>
      </div>
      <Footer/>
    </div>
  );
}
