
export const promos = [

  /*{
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"Essentiels du matin",
    product1:'MACHINE A CAFE DIGITALE - TELEFUNKEN',
    product2:'GRILLE PAIN 1050W INSPIRE WHITE RH - TELEFUNKEN',
    prixPromo: 289,
    prix:270,
    img1:'electromenager/cafe et petit dejeuner/cafe et petit dejeuner MACHINE A CAFE DIGITALE',
    img2:'electromenager/cafe et petit dejeuner/cafe et petit dejeuner Grille pain digital',
  },*/
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"kit cuisine moderne",
    product1:'GRILLE PAIN DIGITAL - TELEFUNKEN',
    product2:'MACHINE A CAFE A CAPSULE - TELEFUNKEN',
    product3:'MACHINE A CAFE DIGITALE - TELEFUNKEN',
    product4:'HACHOIR 500 ML & 400 W - TELEFUNKEN',
    product5:'MIXEUR PLONGEANT STAINLESS STEEL 600W - TELEFUNKEN',
    prixPromo: 752,
    prix:675,
    img1:'electromenager/cafe et petit dejeuner/preparation culinaire Hachoir 500 ml & 400 w',
    img2:'electromenager/cafe et petit dejeuner/preparation culinaire Mixeur Plongeant Stainless steel 600W',
    img3:'electromenager/cafe et petit dejeuner/cafe et petit dejeuner Grille pain digital',
    img4:'electromenager/cafe et petit dejeuner/cafe et petit dejeuner MACHINE A CAFE DIGITALE',
    img5:'electromenager/cafe et petit dejeuner/cafe et petit dejeuner Machine a cafe a capsule',
  },
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"cuisine facile",
    product1:'appareil de cuission Barbecue de table 3 en 1 Cook@Home - RUSSELL HOBBS',
    product2:'Crepiere Fiesta - RUSSELL HOBBS',
    prixPromo: 506,
    prix:455,
    img1:'electromenager/appareil de cuission/appareil de cuission Barbecue de table 3 en 1 Cook@Home',
    img2:'electromenager/appareil de cuission/appareil de cuission Crepiere Fiesta',
  },
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"kit soin complet",
    product1:'TONDEUSE ADVANCED CERAMIQUE RECHARGEABLE REM - REMINGTON',
    product2:'TONDEUSE NEZ ET OREILLES LAMES ADVANCED STEEL - REMINGTON',
    product3:'SECHE CHEVEUX DE VOYAGE - REMINGTON',
    prixPromo: 342,
    prix:315,
    img1:'beaute/rasoir et tendeuse TONDEUSE ADVANCED CERAMIQUE RECHARGEABLE REM-1',
    img2:'beaute/rasoir et tendeuse Tondeuse nez et oreilles Lames Advanced Steel',
    img3:'beaute/coiffure Seche cheveux de voyage',
  },
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"epilation et soin visage",
    product1:'TONDEUSE BIKINI SMOOTH & SILKY - REMINGTON',
    product2:'KIT VISAGE SMOOTH & SILKY REM - REMINGTON',
    prixPromo: 502,
    prix:455,
    img1:'beaute/epilation EPILATEUR SMOOTH & SILKY 5 EN 1',
    img2:'beaute/soin visage et corps KIT VISAGE SMOOTH & SILKY REM-1',
  },
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"soin et elegance",
    product1:'REMINGTON NOSE & EAR TRIMMER - REMINGTON',
    product2:'TONDEUSE A CHEVEUX RECHARGEABLE BLEU - REMINGTON',
    product3:'SECHE CHEVEUX DE VOYAGE - REMINGTON',
    prixPromo: 276,
    prix:255,
    img1:'beaute/rasoir et tendeuse REMINGTON NOSE & EAR TRIMMER',
    img2:'beaute/rasoir et tendeuse TONDEUSE A CHEVEUX RECHARGEABLE BLEU',
    img3:'beaute/coiffure Seche cheveux de voyage',
  },
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"soin visage et bikini",
    product1:'TONDEUSE BIKINI SMOOTH & SILKY - REMINGTON',
    product2:'KIT VISAGE SMOOTH & SILKY REM - REMINGTON',
    prixPromo: 352,
    prix:325,
    img1:'beaute/rasoir et tendeuse TONDEUSE BIKINI SMOOTH & SILKY',
    img2:'beaute/soin visage et corps KIT VISAGE SMOOTH & SILKY REM-1',
  },
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"sechage et lissage",
    product1:'TONDEUSE BIKINI SMOOTH & SILKY - REMINGTON',
    product2:'KIT VISAGE SMOOTH & SILKY REM - REMINGTON',
    prixPromo: 592,
    prix:540,
    img1:'beaute/coiffure LISSEUR CERAMIQUE AVANCE BOTANICALS 230C',
    img2:'beaute/coiffure SECHE CHEVEUX 2300W MOTEUR AC',
  },
  {
    famille: "promos",
    categorie: "promo",
    article: "promo",
    articleName:"duo coiffure",
    product1:'SECHE CHEVEUX THERMACARE PRO 2300 - REMINGTON',
    product2:'FER A BOUCLER PRO BIG CURL 38 MM - REMINGTON',
    prixPromo: 362,
    prix:325,
    img1:'beaute/coiffure SECHE CHEVEUX THERMACARE PRO 2300',
    img2:'beaute/coiffure Fer a boucler Pro Big Curl 38 mm-1',
  },
]
