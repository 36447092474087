import Footer from "../Footer";
import "./style.css";
function Politiques() {


    return (
        <div className="">
            <div className="sommeNousDiv">
            <h2>Politiques de Confidentialités</h2>

<p>Chez eBuy, nous comprenons l'importance de la confidentialité de vos données personnelles. 
Cette politique de confidentialité décrit comment nous recueillons,
 utilisons et protégeons vos informations lorsque vous visitez notre site web ou utilisez nos services.
</p>


<h3>Collecte d'Informations</h3>
<p>Nous recueillons divers types d'informations lorsque vous utilisez notre site web, y compris vos informations personnelles telles que votre nom, adresse e-mail, adresse de livraison et numéro de téléphone lorsque vous effectuez un achat. 
</p>

<h3>Utilisation des Informations</h3>
<p>Nous utilisons les informations que nous recueillons pour traiter vos commandes, personnaliser votre expérience sur notre site, améliorer notre service client et vous informer des offres spéciales et des promotions. Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers à des fins commerciales sans votre consentement explicite.
 </p>

<h3>Protection des Informations</h3>
<p>Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, altération, divulgation ou destruction. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons donc garantir une sécurité absolue.
</p>
<h3>Modifications de la Politique de Confidentialité</h3>
<p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page, et votre utilisation continue de notre site constitue votre acceptation de ces modifications.
</p>
<p>Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, veuillez nous contacter à  <a href="mailto:hello.ebuytn@gmail.com" style={{color:'darkorange'}}> hello.ebuytn@gmail.com </a>
</p>
</div>
        <Footer/>
        </div>
    );
}

export default Politiques;
