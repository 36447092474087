import { Button } from "@mui/material";
import './style.css';
import Footer from '../../Components/Footer';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
export default function Promos() {
  return (
    <div>
      <div className='ourBrand'>
        <h2>Nos Bons Plans pour vous</h2>
        <div className='promoGrid'>
          {itemData.map((item) => (
            <div className={`images-zoom-container `}>
              <div className='promoContainer'>
                <a href={`/bons-plans/${item}`}>
                <img
                  src={require(`../../images/promos/${item}.jpeg`)}
                  alt={item}
                  className='promoImg'
                />
                <Button endIcon={<ArrowRightAltIcon/>} color='warning' className='promoButton'>Profiter</Button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

const itemData = [  
  "kit cuisine moderne",
  "epilation et soin visage",
  "cuisine facile",
  "kit soin complet",
  "soin et elegance",
  "soin visage et bikini",
  "sechage et lissage",
  "duo coiffure",
];
