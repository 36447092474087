/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import ProductBrand from '../../Components/ProductBrandPage';
import ScrollToTop from '../../Components/scrollup';

export default function ProductsMarques() {
  let pathName = window.location.pathname;
  let pathe = pathName.replace(/%20/g, ' ');
  let newPath1 = pathe.split('/')[1].toLocaleUpperCase();
  console.log(newPath1)

  return (
    <div className='products' id='produits'>
      <div className='product'>
        <ScrollToTop/>
       
        <div className='productMarqueContain'>
        <img
            src={require(`../../images/logo/${newPath1}.png`)}
            alt={newPath1}
            className='productLogo'
            key={newPath1}
          />
          <ProductBrand />
        </div>
      </div>
      <Footer/>
    </div>
  );
}
