/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import React, { useState,useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import { promos } from "../../Data/data";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Dialog } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
export default function PromoDetails() {
  const [openDialog, setOpenDialog] = useState(false);
  // Fonction pour ouvrir la boîte de dialogue et afficher l'image zoomée
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  // Fonction pour fermer la boîte de dialogue
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // Vérifie si l'écran est de taille large (lg et supérieur)
  const buttonSize = isLargeScreen ? "medium" : "small";

  let pathName = window.location.pathname;
  let pathe = pathName.replace(/%20/g, " ");
  let path1 = pathe.split("/")[2];

  const [open, setOpen] = React.useState(false);
  const addToCart = () => {
    // Récupérer l'index du produit dans le tableau 'produits'
    const productIndex = promos.findIndex(
      (produit) => produit.articleName === path1
    );

    // Vérifier si le produit existe dans le tableau 'produits'
    if (productIndex !== -1) {
      // Récupérer les détails du produit à partir de 'produits'
      const product = promos[productIndex];

      // Récupérer le panier depuis le stockage local ou initialiser un nouveau panier s'il est vide
      const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

      // Vérifier si le produit existe déjà dans le panier
      const existingProductIndex = existingCart.findIndex(
        (item) => item.articleName === path1
      );

      if (existingProductIndex !== -1) {
        // Si le produit existe déjà dans le panier, augmenter la quantité
        existingCart[existingProductIndex].quantite += 1;
      } else {
        existingCart.push({
          family: product.famille,
          name: product.articleName,
          img: `${path1}`,
          categorie: product.categorie,
          price: product.prix.toFixed(3),
          brand: product.brand,
          reference: product.ref,
          quantite: 1,
        });
      }

      localStorage.setItem("cart", JSON.stringify(existingCart));

      handleClick();

      window.location.reload();
    }
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const updateItemsPerPage1 = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage1(4);
      } else if (windowWidth >= 700) {
        setItemsPerPage1(2);
      } else {
        setItemsPerPage1(2);
      }
    };
    updateItemsPerPage1();
    window.addEventListener("resize", updateItemsPerPage1);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage1);
    };
  }, []);
  const [itemsPerPage1, setItemsPerPage1] = useState(6);
  
  const settings1 = {
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    speed: 600,
    slidesToShow: itemsPerPage1,
    slidesToScroll: 1,
    className: "custom-slider",
  };
  return (
    <div className="products" id="produits">
      <div className="promos">
        <ScrollToTop />
        <div className="productContainpromoDetail">
          <h2 className="pathTitle" key={Math.floor(Math.random() * 1)}>
            {path1}
          </h2>
          <div>
            {promos.map((produit, index) =>
              produit.articleName === path1 ? (
                <div
                  className="productcardDetails"
                  key={index + produit.prixPromo}
                >
                  <Carousel
                    showArrows={true}
                    autoPlay={false}
                    showIndicators={false}
                    showStatus={false}
                    className="custom-carousel"
                  >
                    <div onClick={() => handleOpenDialog()}>
                      <img
                        className="cardDetails"
                        src={require(`../../images/promos/${path1}.jpeg`)}
                        title={path1}
                        alt={path1}
                      />
                    </div>
                    <div>
                      <img
                        className="cardDetails"
                        src={require(`../../images/produits/${produit.img1}.jpeg`)}
                        title={path1}
                        alt={path1}
                      />
                    </div>
                    <img
                      className="cardDetails"
                      src={require(`../../images/produits/${produit.img2}.jpeg`)}
                      title={path1}
                      alt={path1}
                    />
                    {produit.img3?(
                   <img
                      className="cardDetails"
                      src={require(`../../images/produits/${produit.img3}.jpeg`)}
                      title={path1}
                      alt={path1}
                    />):(<></>)}
                    {produit.img4?(
                      <img
                         className="cardDetails"
                         src={require(`../../images/produits/${produit.img4}.jpeg`)}
                         title={path1}
                         alt={path1}
                       />):(<></>)}
                       {produit.img5?(
                        <img
                           className="cardDetails"
                           src={require(`../../images/produits/${produit.img5}.jpeg`)}
                           title={path1}
                           alt={path1}
                         />):(<></>)
                    
}
                  </Carousel>
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    sx={{ zIndex: 9999999 }}
                  >
                    <img
                      className="cardDetails"
                      src={require(`../../images/promos/${path1}.jpeg`)}
                      title={path1}
                      alt={path1}
                    />
                  </Dialog>

                  <div className="morecardDetail">
                  <h3>-{produit.product1}</h3>
                  <h3>-{produit.product2}</h3>
                {produit.product3?(<h3>-{produit.product3}</h3>):(<></>)}
                {produit.product4?(<h3>-{produit.product4}</h3>):(<></>)}
                {produit.product5?(<h3>-{produit.product5}</h3>):(<></>)}
                  <span className="eco">
                    economisez {produit.prixPromo.toFixed(3)-produit.prix} TND !
                    </span>
                    <h4 style={{ textDecoration: "line-through" }}>
                      {produit.prixPromo.toFixed(3)} TND
                    </h4>
                   
                  
                    <h4>{produit.prix.toFixed(3)} TND</h4>

                    <Button
                      sx={{
                        marginTop: 1,
                        marginBottom: 2,
                        fontFamily: "Exo",
                        fontSize: {
                          lg: "14px",
                          md: "12px",
                          sm: "12px",
                          xs: "10px",
                        },
                      }}
                      variant="contained"
                      color="warning"
                      size={buttonSize}
                      fullWidth
                      startIcon={<ShoppingCartIcon />}
                      titleAccess={`Ajouter ${produit.articleName} au panier`}
                      onClick={addToCart}
                    >
                      J'achète
                    </Button>
                    <Snackbar
                      open={open}
                      autoHideDuration={5000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {produit.articleName} a été ajouté au panier !
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
          
        </div>
   
      </div>
      <div className="bestProductDiv">
      <h3>nos packs et nos promotions actuelles</h3>
          <Slider {...settings1}>
            {itemData.map((item) => (
              <div className={`images-zoom-container-home`}>
                <a href={`/bons-plans/${item}`}>
                  <img
                    src={require(`../../images/promos/${item}.jpeg`)}
                    alt={item}
                    className="promoImgHome"
                  />
                  <Button
                    endIcon={<ArrowRightAltIcon />}
                    color="warning"
                    className="promoButton"
                  >
                    Profiter
                  </Button>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      <Footer />
    </div>
  );
}
const itemData = [
  "kit cuisine moderne",
  "epilation et soin visage",
  "cuisine facile",
  "kit soin complet",
  "soin et elegance",
  "soin visage et bikini",
  "sechage et lissage",
  "duo coiffure",
];