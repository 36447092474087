import "./style.css";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import { useInView } from "framer-motion";
import { useRef } from "react";
export default function ProductCard({
  index,
  famille,
  article,
  categorie,
  subcategorie,
  prixFlash,
  prix,
  colors,
  brand,
  reference,
  stock
}) {
  console.log(index)
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
  const categoriee = article.split('-')[0];
  const articleName = article.split('-')[1];
  const articlImg = article.replace(/-/g, ' ');
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 900,
        lg: 1200,
      },
    },
  });
  const [couleurSelectionnee, setCouleurSelectionnee] = useState(null);

  const handleClickCouleur = (color) => {
    setCouleurSelectionnee(color);
    
  };
  const [open, setOpen] = React.useState(false);
  console.log(couleurSelectionnee)
  const addToCart = () => {
    const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
    let productExists = false;

    existingCart.forEach(product => {
      if (product.name === articleName) {
        productExists = true;
        product.quantite += 1; // Incrémenter la quantité si le produit existe déjà
        
      }
    });

    if (!productExists) {
      let price =prixFlash?prixFlash:prix;
      let color=couleurSelectionnee
     
      const product = {
        family: famille,
        name: articleName,
        img: articlImg,
        categorie: categorie,
        souscategorie:subcategorie,
        price: price.toFixed(3),
        brand: brand,
        reference: reference,
        quantite: 1 ,
        color
      };
      
      existingCart.push(product);
      
    }
    

    localStorage.setItem('cart', JSON.stringify(existingCart));
    handleClick();
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose =() => {
    window.location.reload()
    setOpen(false);
  };
  const cartItems = JSON.parse(localStorage.getItem("cart"));

  const cartContain = cartItems ? cartItems.length : 0;
  return (
    <ThemeProvider theme={customTheme} >
        <Card ref={ref2} style={{ 
            transform: isInView2 ? "none" : "translateY(-200px)",
            opacity: isInView2 ? 1 : 0,
            transition: `all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.${5+index}s`
          }}
          className="card"
          sx={{
            height: { lg: "400px", md: "300px", sm: "280px", xs: "240px" },
            width: { lg: "20vw", md: "22.5vw", sm: "22.5vw", xs: "35vw" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2
          }}
          elevation={3}
        >
           {prixFlash && (
            <div className="promoLabel">Vente Flash</div>
          )}
                  {categorie === categoriee ? (
          <a className="cardMediaLink" href={`/produit/${famille}/${categoriee}/${articleName}`} >
            {categorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categoriee)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ):subcategorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categorie)}%2F${decodeURIComponent(subcategorie)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ) : (<></>)}
          </a>):categoriee === subcategorie ? (
          <a className="cardMediaLink" href={`/produit/${famille}/${categorie}/${subcategorie}/${articleName}`}>
            {categorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categoriee)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ):subcategorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categorie)}%2F${decodeURIComponent(subcategorie)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ) : (<></>)}
          </a>):(<></>)}
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="articleDetaile" style={{ fontWeight: 'bold' }}>
                {articleName}
              </span>
              <span className="articleDetaile">
                {brand}
              </span>
             
             
              {(prixFlash?(
                <>
                 <span className="articleDetaile" style={{textDecoration:'line-through',textDecorationColor:'red'}}>
                 { prix.toFixed(3)} TND
              
                </span>
                   <span className="articleDetaile">
                   {prixFlash.toFixed(3)} tnd
                   </span></>):(
              <span className="articleDetaile">
                {prix.toFixed(3)} TND
              </span>))}
              {stock<1?(
                <span className="articleDetailePlus">
               <Button sx={{ fontWeight: 'bold',color:'red',fontSize:{lg:'12px',md:'12px',sm:'10px',xs:'10px'} }} >EN ARRIVAGE  </Button>  </span>):(<></>)}
             
            </div>
            {stock<1?(
              <div>
             
            <AddShoppingCartIcon
              
              sx={{ color: '#1F4D9F', cursor: 'pointer',fontSize:{lg:'32px'},position:'absolute',bottom:'5px',right:'5px' }}
           
            />
           
            </div>
            ):(
              <AddShoppingCartIcon
              
              sx={{ color: '#1F4D9F', cursor: 'pointer',fontSize:{lg:'32px'},position:'absolute',bottom:'5px',right:'5px' }}
              titleAccess={`Ajouter ${articleName} au panier`}
              onClick={addToCart}
            />
            )}
            <Dialog open={open}   sx={{zIndex:'999999999999'}}>
            
            <div className="card">
        <Card
          className="card"
          sx={{
            height: { lg: "600px", md: "450px", sm: "550px", xs: "480px" },
            width: { lg: "20vw", md: "25vw", sm: "35vw", xs: "60vw" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2
          }}
          elevation={3}
        >
          <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[700],
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
         
                  {categorie === categoriee ? (
          <a className="cardMediaLink" href={`/produit/${famille}/${categoriee}/${articleName}`}>
            {categorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categoriee)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ):subcategorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categorie)}%2F${decodeURIComponent(subcategorie)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ) : (<></>)}
          </a>):categoriee === subcategorie ? (
          <a className="cardMediaLink" href={`/produit/${famille}/${categorie}/${subcategorie}/${articleName}`}>
            {categorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categoriee)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ):subcategorie === categoriee ? (
              <>
              
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categorie)}%2F${decodeURIComponent(subcategorie)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
            ) : (<></>)}
          </a>):(<></>)}
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column',width:'90vw',padding:'5px' }}>
              <span className="articleDetaile" style={{ fontWeight: 'bold' }}>
                {articleName}
              </span>
              <span className="articleDetaile">
                {brand}
              </span>
            
              {(prixFlash?(
                <>
                 <span className="articleDetaile" style={{textDecoration:'line-through',textDecorationColor:'red'}}>
                 { prix.toFixed(3)} TND
              
                </span>
                   <span className="articleDetaile">
                   {prixFlash.toFixed(3)} tnd
                   </span></>):(
              <span className="articleDetaile">
                {prix.toFixed(3)} TND
              </span>))}
              {colors?(
                        <>
                      <span className="morecardDetailPrice">Sélectionnez la couleur</span>
      <div className="colorboisDiv">
        {colors.map((color, index) => (
          <img
            key={index}
            src={require(`../../images/colors/${color}.jpeg`)} 
            alt={color}
            className={`boisColor ${color === couleurSelectionnee ? 'selected' : ''}`}
            onClick={() => handleClickCouleur(color)}
          />
        ))}
      </div></>):(<></>)}
              {stock<1?(
                <span className="articleDetailePlus">
               <Button sx={{ fontWeight: 'bold',color:'red',fontSize:{lg:'12px',md:'12px',sm:'10px',xs:'10px'} }} >EN ARRIVAGE  </Button>  </span>):(<></>)}
               <span className="articleDetaile" style={{fontWeight:'bold'}}>
              IL Y A {cartContain} ARTICLE(S) DANS VOTRE PANIER
            </span>
              
               <Button variant="contained" fullWidth color="warning" sx={{ fontWeight: 'bold',fontSize:{lg:'14px',md:'12px',sm:'10px',xs:'10px'},marginTop:'10px' }} onClick={handleClose} > continuer mes achats  </Button>
            <a href="/panier">   <Button fullWidth variant="contained" color="success" sx={{ fontWeight: 'bold',fontSize:{lg:'14px',md:'12px',sm:'10px',xs:'10px'},marginTop:'10px' }} > finaliser ma commande  </Button></a>
            </div>
            </CardContent>
            </Card>
      </div>
      </Dialog>
          </CardContent>
        </Card>
    </ThemeProvider>
  );
}
