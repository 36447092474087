import * as React from 'react';
import './style.css';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { cardData } from '../../Data/cardData';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

export default function ProductNav() {
  const [openIndex, setOpenIndex] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [path1, setPath1] = React.useState('');
  const [path2, setPath2] = React.useState('');
  const [path3, setPath3] = React.useState('');
  React.useEffect(() => {
    let pathName = window.location.pathname;
    let pathe = pathName.replace(/%20/g, ' ');
    let newPath1 = pathe.split('/')[2];
    let newPath2 = pathe.split('/')[3];
    let newPath3 = pathe.split('/')[4];
    setPath1(newPath1);
    setPath2(newPath2);
    setPath3(newPath3);
  }, []); 

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  
  const handleSubItemClick = (index, subIndex) => {
    setActiveIndex(activeIndex === subIndex ? null : subIndex);
  };

  return (
    <div>
      <span className='navbarCollection'>
        collections
        {path1 && <a href={`/collections/${decodeURIComponent(path1)}`}>{` / ${decodeURIComponent(path1)}`} </a>}  
        {path2 && <a href={`/collections/${decodeURIComponent(path1)}/${path2}`}>{` / ${decodeURIComponent(path2)}`}  </a>}
        {path3 && <a href={`/collections/${decodeURIComponent(path1)}/${path2}/${path3}`}>{` / ${decodeURIComponent(path3)}`}  </a>}
       </span>
      <List
        sx={{ width:{lg:'22vw',md:'25vw',sm:'25vw',xs:'40vw'},display:{lg:'flex',md:'flex',sm:'flex',xs:'none'}, bgcolor: 'background.paper',flexDirection:'column',alignItems:'center' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
       {cardData.map((category, index) => (
          <React.Fragment key={index}>
            <ListItemButton onClick={() => handleClick(index)} sx={{display:'flex',flexDirection:'column',alignItems:'flex-start',width:{lg:'22vw',md:'25vw',sm:'25vw',xs:'45vw'},marginTop:2}}>
              <ListItemText  />
              {category.title === 'mobilier' ? (
  <a href={`/collections/${encodeURIComponent(category.title)}`} className={`categoryTitle ${path1 === category.title ? 'redText' : ''}`}>
    {decodeURIComponent(category.title)}
  </a>
) : (
  <span className={`categoryTitle ${path1 === category.title ? 'redText' : ''}`}>
    {category.title}
  </span>
)}

            </ListItemButton>
            {openIndex === index && category.sousFamille.length > 0 && (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{display:'flex',flexDirection:'column',alignItems:'flex-start',width:{lg:'25vw',md:'35vw',sm:'32.5vw',xs:'40vw'}}}>
                {category.sousFamille.map((subItem, subIndex) => (
    <React.Fragment key={subIndex}>
        <ListItemButton onClick={() => handleSubItemClick(index, subIndex)} >
            <ListItemText  />
            {subItem.sousFamilles.length< 1?(
            <a href={`/collections/${encodeURIComponent(category.title)}/${encodeURIComponent(subItem.nom)}`} className={`subfamilyItem ${(decodeURIComponent(path2) === subItem.nom) ? 'select' : ''}`}>
    {subItem.nom}
</a>):(<> <span className={`subfamilyItem ${(decodeURIComponent(path2) === subItem.nom) ? 'select' : ''}`}>{subItem.nom}</span></>)}
{activeIndex === subIndex && subItem.sousFamilles.length > 0 && (
                        <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{display:'flex',flexDirection:'column',alignItems:'flex-start',width:{lg:'25vw',md:'35vw',sm:'32.5vw',xs:'40vw'}}}>
                            {subItem.sousFamilles.map((subItemm, subSubIndex) => (
                               subItemm!==''?(
                              <ListItemButton key={subSubIndex} >
                                <ListItemText />
                                <a href={`/collections/${category.title}/${subItem.nom}/${subItemm}`} className='subfamilyItem'>
                                  <span className={` ${(activeIndex === index && path3 === subItemm) ? 'select' : ''}`}>{subItemm}</span>
                                </a>
                              </ListItemButton>):(<></>)
                            ))}
                          </List>
                        </Collapse>
                      )}
        </ListItemButton>
    </React.Fragment>
))}

                </List>
              </Collapse>
            )}
            
          </React.Fragment>
       ))}
      </List>
      <List
  sx={{
    width: { lg: '22vw', md: '25vw', sm: '25vw', xs: 'auto' },
    display: { lg: 'none', md: 'none', sm: 'none', xs: 'flex' },
    bgcolor: 'background.paper',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflowX: 'auto',
    scrollbarWidth: 'none', 
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  }}
  component="nav"
  aria-labelledby="nested-list-subheader"
>
  {cardData.map((category, index) => (
    <React.Fragment key={index}>
      <ListItemButton
        onClick={() => handleClick(index)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent:'space-around',
          width: { lg: '22vw', md: '25vw', sm: 'auto', xs: 'auto' },
        }}
      >
        <ListItemText />
        {category.title === 'mobilier' ? (
          <a
            href={`/collections/${encodeURIComponent(category.title)}`}
            className={`categoryTitle ${decodeURIComponent(path1) === category.title ? 'redText' : ''}`}
          >
            {decodeURIComponent(category.title)}
          </a>
        ) : (
          <span  className={`categoryTitle ${decodeURIComponent(path1) === category.title ? 'redText' : ''}`}>
            {category.title}
          </span>
        )}
      </ListItemButton>
      {openIndex === index && category.sousFamille.length > 0 && (
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent:'space-around',
              width: { lg: '25vw', md: '35vw', sm: '32.5vw', xs: 'auto' }
            }}
          >
            {category.sousFamille.map((subItem, subIndex) => (
              <React.Fragment key={subIndex}>
                <ListItemButton onClick={() => handleSubItemClick(index, subIndex)}>
                  <ListItemText />
                  {subItem.sousFamilles.length< 1?(
                  <a
                    href={`/collections/${encodeURIComponent(category.title)}/${encodeURIComponent(subItem.nom)}`}
                    className={`subfamilyItemV ${decodeURIComponent(path2) === subItem.nom ? 'select' : ''}`}
                  >
                    {subItem.nom}
                  </a>):(<> <span className={`subfamilyItem ${(decodeURIComponent(path2) === subItem.nom) ? 'select' : ''}`}>{subItem.nom}</span></>)}
                  {activeIndex === subIndex && subItem.sousFamilles.length > 0 && (
                        <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{display:'flex',flexDirection:'column',alignItems:'flex-start',width:{lg:'25vw',md:'35vw',sm:'32.5vw',xs:'40vw'}}}>
                            {subItem.sousFamilles.map((subItemm, subSubIndex) => (
                               subItemm!==''?(
                              <ListItemButton key={subSubIndex} >
                                <ListItemText />
                                <a href={`/collections/${category.title}/${subItem.nom}/${subItemm}`} className='subfamilyItem'>
                                  <span className={` ${(activeIndex === index && path3 === subItemm) ? 'select' : ''}`}>{subItemm}</span>
                                </a>
                              </ListItemButton>):(<></>)
                            ))}
                          </List>
                        </Collapse>
                      )}
                </ListItemButton>
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  ))}
</List>

    </div>
  );
}
