/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import { useInView } from "framer-motion";
import { useRef } from "react";
function Carousel() {
  const [isMobile, setIsMobile] = useState(false);
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    // Écoute du changement de taille de l'écran
    window.addEventListener('resize', handleResize);

    // Appel initial pour déterminer l'état initial
    handleResize();

    // Nettoyage du listener lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slides = ['beaute & sante', 'electromenager', 'luminaire', 'mobilier'];

  const settings = {
    focusOnSelect: true,
    pauseOnHover: false,
    infinite: true,
    speed: 1100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: false,
  };

  return (
    <div className='carouselHome'  ref={ref2} style={{ position:'relative',
      transform: isInView2 ? "none" : "translateY(200px)",
      opacity: isInView2 ? 1 : 0,
      transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
    }}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <a href={`/collections/${encodeURIComponent(slide)}`}>
              <img src={require(`../../images/${slide}.jpg`)} alt={`Slide ${index + 1}`} />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
