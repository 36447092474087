/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import Product100Tri from '../../Components/Product100';
import ScrollToTop from '../../Components/scrollup';
import moin150 from '../../images/moin150.jpeg'
export default function Product100() {
  const moincher=150;
  return (
    <div className='products100' id='produits'>
       <img
            src={moin150}
            alt='-150'
            className='vente100Img'
          />
      <div className='product100'>
        <ScrollToTop/>
       
        <div className='product100Contain'>
       
          <Product100Tri moincher={moincher} />
        </div>
      </div>
      <Footer/>
    </div>
  );
}
