/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import Footer from '../../Components/Footer';
import flash2 from '../../images/venteFlash.jpeg'
//import Countdown from '../../Components/Countdown';
//import { Button } from '@mui/material';
import ScrollToTop from '../../Components/scrollup'
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
//import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export default function VenteFlashPage() {
  const theme = createTheme({
    palette: {
      primary: {
         
              main: '#ffeb3b',
              dark: '#ffcd38',
              contrastText: '#000',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <div className='products100' >
       <img
            src={flash2}
            alt='flash'
            className='flashImgHome'
          /> 
        <div className='venteflashCounter'>  
      <h3>coming soon </h3>
   
      </div>
      
        <ScrollToTop/>       
        <div className='flashContain'>
          {/*
          {itemData.map((item) => (
            <div className={`images-zoom-container-flash`}>
            
                <a href={`${item.link}`}>
                <img
                  src={require(`../../images/flash/${item.img}.jpeg`)}
                  alt={item.img}
                  className='flashImg'
                />
                <Button variant='contained' endIcon={<KeyboardArrowRightIcon/>} size='small' sx={{marginLeft:{lg:'12vw',md:'150px',sm:'110px',xs:'50px'},fontSize:{lg:'12px',md:'12px',sm:'10px',xs:'10px'} }}
                color='primary' className='promoButton'>Profiter</Button>
                </a>
              </div>
          
          ))}
   */}
          
        </div>

    
      <Footer/>
    </div>
    </ThemeProvider>
  );
}
// eslint-disable-next-line no-unused-vars
const itemData = [  
   {img:"flash1",link:'/produit/electromenager/hygiene%20et%20article%20maison/Fer%20a%20repasser%20a%20vapeur%202400W'},
    {img:"flash2",link:'/produit/electromenager/appareil%20de%20cuission/AIR%20FRYER%20AEROFRY%203.5L'},
    {img:"flash3",link:'/produit/electromenager/hygiene%20et%20article%20maison/Fer%20a%20repasser%20a%20vapeur%202800W'},
    {img:"flash4",link:'/produit/electromenager/preparation%20culinaire/mixeur%20plongeant%20300W'},
    {img:"flash5",link:'/produit/electromenager/hygiene%20et%20article%20maison/ASPIRATEUR'},

    {img:"flash6",link:'/produit/electromenager/hygiene%20et%20article%20maison/ASPIRETTE%203,6V'},
    {img:"flash7",link:'/produit/electromenager/preparation%20culinaire/BLENDER%20400W'},
    {img:"flash8",link:'/produit/electromenager/preparation%20culinaire/ROBOT%20750W%2034%20FONCTION'},
    {img:"flash9",link:'/produit/electromenager/preparation%20culinaire/ROBOT%20MINI%20400W'},
    {img:"flash10",link:'/produit/electromenager/preparation%20culinaire/BLENDER%20SPORT%20300W'},

    {img:"flash11",link:'/produit/electromenager/preparation%20culinaire/HACHOIR%202%20VITESSES'},
    {img:"flash12",link:'/produit/electromenager/preparation%20culinaire/BATTEUR%20AVEC%20BOL%20300W'},
    {img:"flash13",link:'/produit/electromenager/appareil%20de%20cuission/PANINI%20CROQUE%20MONSIEUR%203EN1'},
    {img:"flash14",link:'/produit/electromenager/appareil%20de%20cuission/PANINI%20CROQUE%20MONSIEUR'},
    {img:"flash15",link:'/produit/electromenager/appareil%20de%20cuission/FOUR%2045L%20AVEC%20DOUBLE%20VITRAGE'},

    {img:"flash16",link:'/produit/electromenager/appareil%20de%20cuission/FOUR%2035L%20AVEC%20DOUBLE%20VITRAGE'},
    {img:"flash17",link:'/produit/electromenager/appareil%20de%20cuission/MICRO%20ONDES%2020L%20700W'},
    {img:"flash18",link:'/produit/electromenager/appareil%20de%20cuission/PANINI%20CROQUE%20MONSIEUR%202EN1'},
    {img:"flash19",link:'/produit/electromenager/appareil%20de%20cuission/CUISSEUR%20A%20VAPEUR%203%20NIV'},
    {img:"flash20",link:'/produit/electromenager/preparation%20culinaire/HACHOIR%20MINI%20120W'},

    {img:"flash21",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/PRESSE%20AGRUMES%2030W'},
    {img:"flash22",link:'/produit/electromenager/preparation%20culinaire/Grille%20pain%202%20tranches'},
    {img:"flash23",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/MOULIN%20A%20CAFE%20150W'},
    {img:"flash24",link:'/produit/electromenager/preparation%20culinaire/Grille%20pain%202%20tranches%20750W'},
    {img:"flash25",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/CENTRIFUGEUSE%20800W'},

    {img:"flash26",link:'/produit/electromenager/cafe%20et%20petit%20dejeuner/CENTRIFUGEUSE%20400W'},
    {img:"flash27",link:'/produit/electromenager/preparation%20culinaire/BOUILLOIRE%201.7L%202200W'},
    {img:"flash28",link:'/produit/electromenager/preparation%20culinaire/HACHOIR%20VIANDE%201500W'},
  ];