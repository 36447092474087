import "./style.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import * as React from "react";
import {  createTheme, ThemeProvider } from "@mui/material";

export default function NewProductHome({
  famille,
  article,
  categorie,
  prix,
  brand,
  subcategorie,
  prixFlash,
  reference,
  stock
}) {
  const categoriee = article.split('-')[0];
  const articleName = article.split('-')[1];
  const articlImg = article.replace(/-/g, ' ');
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 900,
        lg: 1200,
      },
    },
  });
 
    
  return (
    <ThemeProvider theme={customTheme}>
      <div className="cardNew">
        <Card
          className="cardNew"
          sx={{
            height: { lg: "250px", md: "250px", sm: "240px", xs: "180px" },
            width: { lg: "13vw", md: "20vw", sm: "20vw", xs: "24vw" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2
          }}
          elevation={3}
        >
          
               {categorie === categoriee ? (
          <a className="cardMediaLink" href={`/produit/${famille}/${categoriee}/${articleName}`}>
           
              <>
              
              <img
                className="cardMediaNew"
                src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categoriee)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
           
          </a>
           ) :subcategorie === categoriee ? (
            <>
               <a className="cardMediaLink" href={`/produit/${famille}/${categorie}/${subcategorie}/${articleName}`}>
            <img
              className="cardMediaNew"
              src={`https://firebasestorage.googleapis.com/v0/b/ebuy-10d2e.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(categorie)}%2F${decodeURIComponent(subcategorie)}%2F${decodeURIComponent(articlImg)}.jpeg?alt=media`}
              title={articleName}
              alt={articleName}
            />
            </a>
            </>
          ) : (<></>)}
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="articleDetaileNew" style={{ fontWeight: 'bold' }}>
                {articleName}
              </span>
              <span className="articleDetaileNew">
                {brand}
              </span>
             
              <span className="articleDetaileNew">
                {prix.toFixed(3)} TND
              </span>
            
           
            </div>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}
